import { createSlice } from "@reduxjs/toolkit";

interface SortState {
  value: { label: string; value: string };
}

const initialState: SortState = {
  value: { label: "", value: "" },
};

const companyProductSortSlice = createSlice({
  name: "companyProductSort",
  initialState,
  reducers: {
    updateCompanyProductSort(state, action) {
      state.value = action.payload;
    },
  },
});

export const { updateCompanyProductSort } = companyProductSortSlice.actions;
export const companyProductSortState = (state: any) => state.companyProductSort.value;
export default companyProductSortSlice.reducer;
