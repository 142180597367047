import React from "react";
import * as ls from "local-storage";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { isTokenValid } from "./services/accessToken.service";
import { customToast } from "./pages/shared-components/custom-toast";
import { useSelector } from "react-redux";
import { userDetails } from "./redux/slices/userDataSlice";

let hasShownToast = false;

type AuthenticatedRouteType = {
  role: Array<string>;
};

export default function AuthenticatedRoute({ role }: AuthenticatedRouteType) {
  const accessToken: string | null = ls.get("accessToken");
  const location = useLocation();
  const userData = useSelector(userDetails) || null;

  // Initialize an array to store permission names
  const permissionName: string[] = []; // Explicitly define the type as 'string[]'

  // Iterate through the user's groups to extract permission names
  userData.groups.forEach((group: { permissions: any; name: string }) => {
  // Loop through each permission within the group
  group.permissions.forEach((permission: any) => {
    // Extract the codename of the permission
    const permissionCodename = permission.codename;
    
    // Add the extracted codename to the permissionName array
    permissionName.push(permissionCodename);
  });
});



  if (!isTokenValid(accessToken)) {
    if (!hasShownToast) {
      customToast({ message: "You must log in first.", type: "error" });
      hasShownToast = true;
    }
    return (
      <Navigate
        to="/login"
        state={{
          from: location.pathname,
        }}
        replace={true}
      />
    );
  }

  if (!(role.includes("admin") && role.includes("user"))) {
    if (
      !permissionName.includes("add_company")
    ) {
      if (!hasShownToast) {
        customToast({ message: "Unauthorized Access.", type: "error" });
        hasShownToast = true;
      }
      return (
        <Navigate
          to="/login"
          state={{
            from: "/",
          }}
          replace={true}
        />
      );
    }
  }
  return <Outlet />;
}
