const TooltipCustom = () => {
  return (
    <div className="absolute top-[calc(-4rem)] ml-[-5%] text-center bg-[#f74343] text-white text-xs px-2 py-1 rounded shadow">
      The maxmum value can't be <span className="font-bold">smaller</span> than
      the minimum.
      <svg
        className="absolute text-[#f74343] h-2 w-full left-0 top-full"
        x="0px"
        y="0px"
        viewBox="0 0 255 255"
      >
        <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
      </svg>
    </div>
  );
};

export default TooltipCustom;
