import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SecurityIcon } from "../../../assets/icons/lock-8.svg";
import { Notify } from "../../shared-components/custom-alert";
import { SettingsLayout } from "./settings.layout";

import PasswordValidator from "password-validator";
import { deleteUser } from "../../../services/delete-user.service";

import { logout, userDetails } from "../../../redux/slices/userDataSlice";
import { customToast } from "../../shared-components/custom-toast";

export const SecuritySettingsPage = () => {
  const userData = useSelector(userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      verifyNewPassword: "",
    },
  });

  const schema = new PasswordValidator();
  schema
    .has()
    .uppercase(1)
    .has()
    .lowercase(1)
    .has()
    .digits(1)
    .has()
    .not()
    .spaces()
    .has()
    .symbols(1);

  const validateForm = () => {
    return (
      watch("currentPassword").length &&
      watch("newPassword").length &&
      watch("verifyNewPassword").length
    );
  };

  const [alert, setAlert] = useState({ isSuccess: false, message: "" });

  const handleDeleteUser = useCallback(() => {
    Notify.confirm({
      confirmButtonText: "Yes, delete",
      isDestructive: true,
      text: "Are you sure? This action cannot be undone.",
      title: "Delete account",
    }).then(({ isDismissed }) => {
      if (!isDismissed) {
        // TODO: Call Delete User api here
        deleteUser(userData.id)
          .then((res) => {
            dispatch(logout());
            customToast({ message: res.data.msg, type: "success" });
          })
          .then(() => {
            navigate("/", { replace: true });
          })
          .catch((error) => {
            customToast({
              message: `${
                error.response.data.detail || "Something went wrong"
              }`,
              type: "error",
            });
          });
      }
      return;
    });
  }, [dispatch, navigate, userData.id]);

  const handlePasswordUpdate = (formData: any) => {
    setAlert({ isSuccess: false, message: "" });

    const result = schema.validate(formData.newPassword, { list: true });
    if (Object.keys(result).length !== 0) {
      setAlert({
        isSuccess: false,
        message:
          "Password must contain 1 uppercase, 1 lowercase, 1 special character and 1 number. No spaces allowed.",
      });
      return;
    }
    // TODO: Call Reset password api here
    setAlert({
      isSuccess: true,
      message: "Password changed successfully :)",
    });
  };

  return (
    <SettingsLayout
      color="coral"
      icon={<SecurityIcon />}
      text="Change your password or permanently delete your account."
      title="Privacy &amp; security"
    >
      <div className="bg-white rounded border border-gray-400 p-8 md:p-12 mb-6">
        <div className="font-semibold border-b border-gray-200 pb-2 mb-6">
          Change password
        </div>
        <form
          className="mx-auto max-w-full lg:max-w-md"
          onSubmit={handleSubmit(handlePasswordUpdate)}
        >
          <fieldset className="mb-6">
            <label className="block mb-1" htmlFor="current-password">
              Current password
            </label>
            <input
              id="current-password"
              type="password"
              className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Enter current password"
              {...register("currentPassword", {
                required: "This input is required.",
              })}
            />
            {errors.currentPassword?.message ? (
              <div className="px-3 text-red-400">
                {errors.currentPassword?.message}
              </div>
            ) : (
              <></>
            )}
          </fieldset>
          <fieldset className="mb-6">
            <label className="block mb-1" htmlFor="new-password">
              New password
            </label>
            <input
              id="new-password"
              type="password"
              className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Enter new password"
              {...register("newPassword", {
                maxLength: {
                  value: 68,
                  message: "This input can't exceed 254 characters",
                },
                minLength: {
                  value: 6,
                  message: "This input requires at least 6 characters",
                },
                validate: (val: string) => {
                  if (watch("currentPassword") === val) {
                    return "Current & New password are same.";
                  }
                },
              })}
            />
            {errors.newPassword?.message ? (
              <div className="px-3 text-red-400">
                {errors.newPassword?.message}
              </div>
            ) : (
              <></>
            )}
          </fieldset>
          <fieldset className="mb-6">
            <label className="block mb-1" htmlFor="verify-new-password">
              Verify new password
            </label>
            <input
              id="verify-new-password"
              type="password"
              className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Enter new password again"
              {...register("verifyNewPassword", {
                validate: (val: string) => {
                  if (watch("newPassword") !== val) {
                    return "Passwords does not match";
                  }
                },
              })}
            />
            {errors.verifyNewPassword?.message ? (
              <div className="px-3 text-red-400">
                {errors.verifyNewPassword?.message}
              </div>
            ) : (
              <></>
            )}
          </fieldset>
          <fieldset className="text-right">
            <input
              className="w-full md:w-auto bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
              type="submit"
              disabled={!validateForm()}
              value="Change"
            />
          </fieldset>
          <div
            className={`text-center text-sm px-4 py-3 rounded mt-4 ${
              alert.message.length > 0 ? "" : "hidden"
            } ${
              alert.isSuccess
                ? "bg-blue-100 border border-blue-400 text-blue-600"
                : "bg-red-100 border border-red-400 text-red-600"
            }`}
          >
            {alert ? alert.message : ""}
          </div>
        </form>
      </div>
      <div className="bg-white rounded border border-gray-400 p-8 md:p-12 mb-6">
        <div className="font-semibold border-b border-gray-200 pb-2 mb-6">
          Delete account
        </div>
        <div className="text-center">
          <div className="block mb-2">
            All your data will be deleted from our servers forever.
          </div>
          <div className="block text-red-700 font-semibold mb-6">
            This action cannot be undone.
          </div>
          <button
            className="w-full md:w-auto bg-red-700 text-white px-6 py-3 rounded cursor-pointer"
            onClick={handleDeleteUser}
          >
            Delete
          </button>
        </div>
      </div>
    </SettingsLayout>
  );
};
