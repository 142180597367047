import { useState, FC, PropsWithChildren } from "react";
import HeaderButton from "../../headerButton";
import { useDispatch } from "react-redux";
import { updateSort } from "../../../../../redux/slices/sortingFilterSlice";
import { useMediaQuery } from "../../../../../hooks/use-media-query";
import { ReactComponent as Change } from "../../../../../icons/change.svg";

const ListHeader: FC<PropsWithChildren<any>> = ({ onViewChange }) => {
  const [company, setCompany] = useState(false);
  const [product, setProduct] = useState(false);
  const [baseRate, setBaseRate] = useState(false);
  const [reypayment, setReypayment] = useState(false);
  const [effectiveRate, setEffectiveRate] = useState(false);

  const dispatch = useDispatch();

  const toggleCompany = () => {
    setProduct(false);
    setBaseRate(false);
    setReypayment(false);
    setEffectiveRate(false);
    if (!company) {
      dispatch(
        updateSort({ label: "Company", value: "company" })
      );
      setCompany(!company);
    }
  };
  const toggleProduct = () => {
    setProduct(!product);
    setBaseRate(false);
    setReypayment(false);
    setEffectiveRate(false);
    if (!product) {
      dispatch(updateSort({ label: "Product", value: "product" }));
    } else {
      dispatch(updateSort({ label: "Financier", value: "financier" }));
    }
    if (!baseRate && !reypayment && !effectiveRate && product) {
      setCompany(true);
    }
    else{
      setCompany(false);
    }
  };
  const toggleBaseRate = () => {
    setProduct(false);
    setBaseRate(!baseRate);
    setReypayment(false);
    setEffectiveRate(false);
    if (!baseRate) {
      dispatch(updateSort({ label: "Base", value: "base" }));
    } else {
      dispatch(updateSort({ label: "Financier", value: "financier" }));
    }
    // if (baseRate && !reypayment && !effectiveRate && !product) {
    //   setCompany(true);
    // }
    // else{
    //   setCompany(false);
    // }
  };

  const toggleReypayment = () => {
    setProduct(false);
    setBaseRate(false);
    setReypayment(!reypayment);
    setEffectiveRate(false);
    if (!reypayment) {
      dispatch(updateSort({ label: "Repayment", value: "repayment" }));
    } else {
      dispatch(updateSort({ label: "Financier", value: "financier" }));
    }
    // if (!baseRate && reypayment && !effectiveRate && !product) {
    //   setCompany(true);
    // }
    // else{
    //   setCompany(false);
    // }
  };

  const toggleEffectiveRate = () => {
    setProduct(false);
    setBaseRate(false);
    setReypayment(false);
    setEffectiveRate(!effectiveRate);
    if (!effectiveRate) {
      dispatch(updateSort({ label: "Effective", value: "effective" }));
    } else {
      dispatch(updateSort({ label: "Financier", value: "financier" }));
    }
    // if (!baseRate && !reypayment && effectiveRate && !product) {
    //   setCompany(true);
    // }
    // else{
    //   setCompany(false);
    // }
  };

  const isMobile = useMediaQuery("(max-width: 1010px)");

  return (
    <div className={`flex tracking-widest mb-[2%] px-2 ${isMobile && "flex-col"}`}>
      <div
        className={`${
          isMobile ? "w-full" : "w-1/6"
        } items-center flex flex-row`}
      >
        <HeaderButton
          showArrow={false}
          title={"Company"}
          // toggle={toggleCompany}
          toggle={()=>{}}
          selected={company}
        />
        {/* <div className="px-1">/</div>
        <HeaderButton
          showArrow={true}
          title={"Product"}
          toggle={toggleProduct}
          selected={product}
        />
        <div onClick={() => onViewChange()}>
          <Change className=" fill-[#f5f5f5]" />
        </div> */}
      </div>
      <div className="w-1/6"></div>
      {/* {isMobile ? (
        <HeaderButton
          showArrow={false}
          title={"Bracket"}
          toggle={() => {}}
          selected={false}
        />
      ) : (
        <>
          <div className="w-1/6"></div>
          <div className="w-1/6 flex justify-items-start">
            {" "}
            <HeaderButton
              showArrow={false}
              title={"Bracket"}
              toggle={() => {}}
              selected={false}
            />
          </div>
        </>
      )} */}
      <div
        className={` ${
          isMobile ? "flex flex-col" : "w-4/6 grid grid-cols-3 gap-5"
        } justify-items-end`}
      >
        <HeaderButton
          showArrow={true}
          title={"Base Rate"}
          toggle={toggleBaseRate}
          selected={baseRate}
        />
        <HeaderButton
          showArrow={true}
          title={"Effective Rate"}
          toggle={toggleEffectiveRate}
          selected={effectiveRate}
        />
        <HeaderButton
          showArrow={true}
          title={"Repayment"}
          toggle={toggleReypayment}
          selected={reypayment}
        />
      </div>
    </div>
  );
};

export default ListHeader;
