import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    selectedComapnyId: -1,
    productsData: null,
    selectedProductId: -1,
    bracketData: null,
    upliftData: null,
    addUpliftData: null,
    editUpliftData: null,
    isLoading: false,
    isSideBarVisible: false,
    orgLoading: false,
    productLoading: false,
    bracketLoading: false,
    upliftLoading: false,
    toggleCompany: false
  },
};

const directorySlice = createSlice({
  name: "directory",
  initialState,
  reducers: {
    updateDirectoryComapnyId(state, action) {
      state.value.selectedComapnyId = action.payload;
    },
    updateToggleCompany(state, action){
      state.value.toggleCompany = action.payload;
    },
    updateProductsData(state, action) {
      state.value.productsData = action.payload;
    },
    updateBracketData(state, action) {
      state.value.bracketData = action.payload;
    },
    updateUpliftData(state, action) {
      // @ts-ignore
      state.value.upliftData = { ...state.value.upliftData, ...action.payload };
    },
    updateAddUpliftData(state, action) {
      // @ts-ignore
      state.value.addUpliftData = {...state.value.addUpliftData,
        ...action.payload,
      };
    },
    updateEditUpliftData(state, action) {
      // @ts-ignore
      state.value.editUpliftData = {...state.value.editUpliftData,
        ...action.payload,
      };
    },
    initializeAddUpliftData(state, action){
      state.value.addUpliftData = action.payload;
    },
    setIsSideBarVisible(state, action){
      state.value.isSideBarVisible = action.payload;
    },
    initializeEditUpliftData(state, action){
      state.value.editUpliftData = {
        ...action.payload,
        fields_name: action.payload.fields_name
      };
    },
    clearAddUpliftData(state) {
      state.value.addUpliftData = null;
    },
    clearEditliftData(state) {
      state.value.editUpliftData = null;
    },
    clearUpliftData(state) {
      // @ts-ignore
      state.value.upliftData = {};
    },
    updateDirectoryProductId(state, action) {
      state.value.selectedProductId = action.payload;
    },
    updateLoading(state, action) {
      state.value.isLoading = action.payload;
    },
    updateorgLoading(state, action) {
      state.value.orgLoading = action.payload;
    },
    updateproductLoading(state, action) {
      state.value.productLoading = action.payload;
    },
    updateupliftLoading(state, action) {
      state.value.upliftLoading = action.payload;
    },
    updatebracketLoading(state, action) {
      state.value.bracketLoading = action.payload;
    },
  },
});

export const {
  updateProductsData,
  updateDirectoryComapnyId,
  updateDirectoryProductId,
  updateBracketData,
  updateUpliftData,
  updateLoading,
  updateAddUpliftData,
  clearAddUpliftData,
  clearUpliftData,
  initializeAddUpliftData,
  clearEditliftData,
  updateEditUpliftData,
  initializeEditUpliftData,
  setIsSideBarVisible,
  updateorgLoading,
  updatebracketLoading,
  updateproductLoading,
  updateupliftLoading,
  updateToggleCompany
} = directorySlice.actions;
export const directoryState = (state: any) => state.directory.value;
export default directorySlice.reducer;
