import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../slices/userDataSlice'
import calculatorReducer from '../slices/calculatorSlice'
import sortRecucer from "../slices/sortingFilterSlice"
import companyProductSortingReducer from '../slices/companyProductSortingSlice'
import directoryReducer from '../slices/directorySlice'

export default configureStore({
    reducer: {
        User: userReducer,
        Calculator: calculatorReducer,
        sort: sortRecucer,
        companyProductSort: companyProductSortingReducer,
        directory: directoryReducer
    }
})