import { useState, useEffect } from "react";

function SubmitLoader() {
  const [colors, setColors] = useState(["#3e2d8d", "#9c7c75", "#c29b6b"]);
  const [currentColorIndex, setCurrentColorIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 250); // Change color every second

    return () => clearInterval(interval);
  }, [colors.length]);

  return (
    <div className="w-[55px] h-[44px] rounded-md flex items-center justify-center bg-[#fbca5d]">
      <div className="flex gap-2">
        <div
          className="w-1 h-1 rounded-full animate-pulse"
          style={{ backgroundColor: colors[currentColorIndex] }}
        ></div>
        <div
          className="w-1 h-1 rounded-full animate-pulse"
          style={{
            backgroundColor:
              colors[(currentColorIndex + 1) % colors.length],
          }}
        ></div>
        <div
          className="w-1 h-1 rounded-full animate-pulse"
          style={{
            backgroundColor:
              colors[(currentColorIndex + 2) % colors.length],
          }}
        ></div>
      </div>
    </div>
  );
}

export default SubmitLoader;
