import React, { FC, PropsWithChildren } from "react";

export const ColorCard: FC<
  PropsWithChildren<{ color: string; colorWidth?: number }>
> = ({ color, colorWidth = 4, children }) => {
  return (
    <div className="block bg-white rounded-b border-l border-r border-b border-gray-400">
      <div className={`border-t-${colorWidth} border-${color}`}>{children}</div>
    </div>
  );
};
