import { FC, PropsWithChildren, useEffect, useState } from "react";

import RateRow from "../rate-row";
import ListHeader from "./list-header";

const ResultListView: FC<PropsWithChildren<any>> = ({
  repaymentResultsData,
  onViewChange,
}) => {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    if (repaymentResultsData) {
      let tempData: any = [];
      Object.entries(repaymentResultsData).map(([key, assetData]: any) =>
        tempData.push(...assetData)
      );
      setData(tempData);
    }
  }, [repaymentResultsData]);
  return (
    <div className="w-full">
      <ListHeader onViewChange={onViewChange} />
      <div className="bg-white divide-y-2 rounded px-8 py-2">
        {data.map((asset: any, idx: number) => {
          return (
            <>
              <RateRow
                isGrid={false}
                title={asset}
                company_id={asset.company_id}
                company_name={data.company_name}
                base_range={
                  parseFloat(asset.base_min).toFixed(2) +
                  "% - " +
                  parseFloat(asset.base_max).toFixed(2) +
                  "%"
                }
                effective_range={
                  parseFloat(asset.e_min).toFixed(2) +
                  "% - " +
                  parseFloat(asset.e_max).toFixed(2) +
                  "%"
                }
                repayment_range={
                  "$" + asset.repayment_min + " - $" + asset.repayment_max
                }
                key={idx}
              />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ResultListView;
