import "./loader.css";
import BigLoader from "../../../icons/calculator_loader.png"
import { FC, PropsWithChildren } from "react";

const Loader: FC<PropsWithChildren<any>>  = ({currentWidth}) => {
  return (
    <div className={`fixed top-100 mt-[-2.6%] left-45 h-[60%] rounded-lg flex justify-center items-center z-50`} style={{width: `${currentWidth}px`}}>
      <div className="w-24 h-24 bg-[#3e2d8d] rounded-full flex justify-center items-center">
        <img src={BigLoader} className=" animate-spin"/>
      </div>
    </div>
  );
}

export default Loader;
