import React, { FC, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  updateAddUpliftData,
  updateEditUpliftData
} from "../../../../../redux/slices/directorySlice";
import { useSelector } from "react-redux";
import { directoryState as ds } from "../../../../../redux/slices/directorySlice";
import capitalizeFirstLetter from "../../../../../helpers/capitalizeFirstLetter";

interface Props {
  type: string;
}

function decapitalize(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

const AssetStateDropdown: FC<Props> = ({ type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = ["New", "Used", "Demo"];
  const directoryState = useSelector(ds);
  const [selectedOption, setSelectedOption] = useState(
    (type !== "add" &&
      directoryState.upliftData.asset_state &&
      capitalizeFirstLetter(directoryState.upliftData.asset_state)) ||
      "Select"
  );
  const dispatch = useDispatch();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (data: string) => {
    setSelectedOption(data);
    toggleDropdown();
    {
      type !== "add"
        ? dispatch(updateEditUpliftData({ asset_state: decapitalize(data) }))
        : dispatch(updateAddUpliftData({ asset_state: decapitalize(data) }));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full pr-[2%]" ref={dropdownRef}>
      <div
        className={`rounded-md cursor-pointer bg-[#f2f2f2] appearance-none py-2 px-3 ${
          !isOpen
            ? `border-4 border-transparent text-black`
            : "border-[#463691] border-4"
        }`}
        onClick={toggleDropdown}
      >
        <div className="flex justify-between items-center">
          <span className={`${selectedOption === "Select" ? "opacity-70" : "font-bold"}`}>
            {selectedOption}
          </span>
          <svg
            className={`w-4 h-4 transition-transform transform ${
              isOpen ? "-rotate-180" : "rotate-0"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <ul className="absolute z-10 bg-[#3e2d8d] border border-gray-300 rounded-md mt-1 w-[98%]">
          {options.map((data: any, index) => {
            return (
              <React.Fragment key={index}>
                <li
                  className="cursor-pointer p-4 hover:rounded mx-1 my-1 hover:bg-black text-white hover:text-[#dcb151] font-[700]"
                  onClick={() => handleSelect(data)}
                >
                  {data}
                </li>
                {index !== options.length - 1 && (
                  <div className="mx-3 border-b border-gray-500" />
                )}
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default AssetStateDropdown;
