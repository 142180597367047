import React, { useState, useCallback, FC, useEffect } from "react";
import { ReactComponent as Plus } from "../../../../icons/plus.svg";
import { customToast } from "../../../shared-components/custom-toast";
import { HorizontalDivider } from "../../form-components/divider";
import CompanyTypeDropdown from "./customDropdown/companyTypeDropdown";
import {
  createCompany,
  updateCompanyData,
} from "../../../../services/company.service";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as Star } from "../../../../icons/star.svg";
import { ReactComponent as DeleteIcon } from "../../../../icons/delete_outlined.svg";

interface Props {
  type: String;
  companyData?: any;
  setReloadData: any;
  reloadData: boolean;
  showModal: boolean;
  setShowModal: any;
  setShowDeleteModal: any;
}

const AddDirectoryModal: FC<Props> = ({
  type,
  companyData,
  setReloadData,
  reloadData,
  showModal,
  setShowModal,
  setShowDeleteModal
}) => {
  const initialValues = {
    company_id: null,
    name: null,
    type: null,
    abn: null as null | String,
  };
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });
  const [formData, setFormData] = useState(initialValues);
  useEffect(() => {
    if (companyData && type && type !== "add") {
      const { created_at, updated_at, id, ...payload } = companyData;
      setFormData({ ...payload, company_id: id });
    }
  }, [companyData, type]);
  const updateForm = (key: any, value: any) => {
    setFormData({ ...formData, [key]: value });
  };
  const handleSave = () => {
    if (formData.name && formData.type && formData.abn) {
      if (formData.abn.length !== 11) {
        errorCallback({
          response: {
            data: { detail: "Abn must have 11 digit." },
          },
        });
        return;
      }
      const payload = {
        ...formData,
      };
      if (type === "add") {
        createCompany(payload).then(successCallback).catch(errorCallback);
      } else {
        updateCompanyData(payload).then(successCallback).catch(errorCallback);
      }
    } else {
      errorCallback({
        response: {
          data: { detail: "Incomplete form details." },
        },
      });
    }
  };
  const successCallback = useCallback((res: any) => {
    customToast({ message: res.data.message, type: "success" });
    setShowModal(false);
    setReloadData(!reloadData);
    setFormData(initialValues);
  }, []);

  const errorCallback = useCallback((error: any) => {
    customToast({
      message: `${error.response.data.detail || "Something went wrong"}`,
      type: "error",
    });
  }, []);
  const isDisabled = () => {
    if (
      !formData.name ||
      !formData.abn ||
      !formData.type ||
      // @ts-ignore
      formData.name?.length > 15
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
      {type && type === "add" ? (
        <button
          className="mr-[2%] border-2 border-opacity-20 border-black rounded-md cursor-pointer p-[0.8%]"
          type="button"
          onClick={() => setShowModal(true)}
        >
          <Plus />
        </button>
      ) : (
        <div
          className="border-2 border-[#c4c4c4] text-black font-[700] px-4 py-2 rounded-md cursor-pointer"
          onClick={() => setShowModal(true)}
        >
          Edit
        </div>
      )}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-4 px-8 rounded-t">
                  <div
                    className={`flex ${
                      isSmallScreen ? "flex-col" : "flex-row w-full"
                    } justify-between items-center my-2`}
                  >
                    <h3 className="text-3xl font-semibold font-grotesk">
                      {type && type === "add" ? "Add" : "Edit"} Organization
                    </h3>
                    <div
                      className={`flex ${
                        isSmallScreen ? "flex-col" : "flex-row"
                      }`}
                    >
                      {type !== "add" && (
                        <button
                          className="border-2 border-[#cccccc] px-4 rounded-md py-2 font-bold text-base ml-4"
                          type="button"
                          onClick={() => {
                            setShowDeleteModal(true);
                            setShowModal(false);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      <button
                        className={`${
                          isSmallScreen ? "my-2" : "mx-4"
                        } border-2 border-[#cccccc] rounded-md py-2 px-6 font-bold text-base`}
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={`${
                          isDisabled() ? "bg-[#c5c0dd]" : "bg-[#3e2d8d]"
                        } text-white font-bold px-8 py-2 rounded-md text-base`}
                        onClick={() =>
                          !isDisabled() ? handleSave() : () => {}
                        }
                        disabled={isDisabled()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                {!isSmallScreen && (
                  <div className="mx-8">
                    <HorizontalDivider />
                  </div>
                )}
                <div className="relative p-5 flex-auto">
                  <div className="mx-2 flex flex-row">Name <Star className="mx-1"/></div>
                  {
                    // @ts-ignore
                    formData.name && formData.name.length > 15 && (
                      <>
                        <div className="absolute top-[calc(0.75rem)] ml-[1%] text-center bg-[#f74343] text-white text-sm px-2 py-1 rounded shadow">
                          The maximum length of name should be 15.
                        </div>
                      </>
                    )
                  }
                  <input
                    placeholder="Enter"
                    className={`bg-[#f2f2f2] p-3 font-bold w-[98%] ${
                      formData.name &&
                      // @ts-ignore
                      formData.name.length > 15 &&
                      "border-2 border-[#f74343]"
                    } rounded-md mx-2`}
                    value={formData.name || ""}
                    onChange={(e) => updateForm("name", e.target.value)}
                  />
                  <div
                    className={`flex ${
                      isSmallScreen ? "flex-col" : "flex-row"
                    } my-3`}
                  >
                    <div
                      className={`flex flex-col ${!isSmallScreen && "w-1/2"}`}
                    >
                      <div className="mx-2 my-2 flex flex-row">Type <Star className="mx-1"/></div>
                      {type !== "add" ? (
                        <CompanyTypeDropdown
                          updateForm={updateForm}
                          type={companyData.type}
                        />
                      ) : (
                        <CompanyTypeDropdown updateForm={updateForm} />
                      )}
                    </div>
                    <div
                      className={`flex flex-col ${
                        !isSmallScreen && "w-1/2 mx-2"
                      }`}
                    >
                      <div className=" mx-2 my-2 flex flex-row">ABN <Star className="mx-1"/></div>
                      <input
                        className="bg-[#f2f2f2] p-3 font-bold w-[98%] rounded-md mx-2 outline-none border-none"
                        placeholder="Enter"
                        type="number"
                        value={parseInt(formData.abn?.toString() || "")}
                        onChange={(e) => updateForm("abn", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AddDirectoryModal;
