import React, { FC, } from "react";
import { customToast } from "../../../shared-components/custom-toast";

interface Props {
  setEditModal: any;
  showDeleteModal: boolean;
  setShowdeleteModal: any;
  title: string;
  name:string;
  handleDelete: ()=>void;
}

const DeleteModal: FC<Props> = ({
  setEditModal,
  showDeleteModal,
  setShowdeleteModal,
  title,
  name,
  handleDelete
}) => {
  return (
    <>
      {showDeleteModal ? (
        <>
          <div className="justify-center text-black items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex p-2 flex-col justify-center items-center w-[28rem] bg-white outline-none focus:outline-none">
                <div className=" font-[700] text-3xl my-10">
                  Deleting {title}
                </div>
                <div className="text-lg text-center flex mx-12 my-2">
                  <div>
                    This operation cannot be reversed. Are you sure you want to
                    delete{" "}
                    <span className="font-bold">
                      "{name}"
                    </span>{" "}
                    permanently?
                  </div>
                </div>
                <div className="flex flex-row my-10">
                  <button
                    className=" border-2 border-[#cccccc] px-12 rounded-md py-2 font-bold text-base mx-2"
                    onClick={() => {
                      setShowdeleteModal(false);
                      setEditModal(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#3e2d8d] text-white font-bold px-14 py-2 rounded-md mx-2 text-base"
                    onClick={() => {handleDelete();}}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default DeleteModal;
