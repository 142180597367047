import { default as axios } from "axios";
import { UPLIFTS_API_URL } from "../api.routes";
import { authHeader } from "../auth.header";

export async function createUplifts(params: object) {
  const res = await axios.post(UPLIFTS_API_URL, params, {
    headers: authHeader(),
  });
  return res;
}

export async function getUpliftsOfProductAndRateBracket(
  product_id: number,
  rate_bracket_id: number
) {
  return await axios.get(UPLIFTS_API_URL + `?product_id=${product_id}&rate_bracket_id=${rate_bracket_id}`, {
    headers: authHeader(),
  });
}

export async function getUplift(uplift_id: number) {
  return await axios.get(UPLIFTS_API_URL + uplift_id, {
    headers: authHeader(),
  });
}

export async function updateUpliftData(params: any) {
  return await axios.patch(UPLIFTS_API_URL + params.uplift_id + "/", params, {
    headers: authHeader(),
  });
}

export async function deletUplift(uplift_id:number){
  return await axios.delete(UPLIFTS_API_URL + "delete/" + uplift_id, {
    headers: authHeader(),
  });
}
