import { useState, useEffect } from "react";

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaMatch = window.matchMedia(query);
    if (mediaMatch.matches !== matches) {
      setMatches(mediaMatch.matches);
    }
    const handler = () => setMatches(mediaMatch.matches);
    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, [matches, query]);

  return matches;
};
