import { createSlice } from "@reduxjs/toolkit";
import * as ls from "local-storage";
import { isTokenValid } from "../../services/accessToken.service";
import { getUserDetailsViaToken } from "../../services/profile.service";

export function getInitialUserState(): any {
  const isLoggedIn =
    ls.get("accessToken") !== undefined || null
      ? isTokenValid(ls.get("accessToken"))
      : false;

  if (isLoggedIn) {
    getUserDetailsViaToken();
  } // Set userData in Localstorage
  else {
    ls.clear();
  }

  return {
    isLoggedIn: isLoggedIn,
    userData: isLoggedIn ? JSON.parse(ls.get("userData")) : null || null,
    hasProfile: false,
  };
}

export const userDataSlice = createSlice({
  name: "User",
  initialState: getInitialUserState(),
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = action.payload["isLoggedIn"];
      const userDetails = JSON.parse(ls.get("userData"));
      state.userData = userDetails;
      state.hasProfile =
        userDetails.first_name && userDetails.last_name ? true : false;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.userData = null;
      state.hasProfile = false;
      ls.clear();
    },
    updateUserProfile: (state, action) => {
      state.userData = action.payload["userData"];
      state.hasProfile = action.payload["hasProfile"];
    },
  },
});

export const { login, logout, updateUserProfile } = userDataSlice.actions;

export const userStatus = (state: any) => state.User.isLoggedIn;
export const userProfileStatus = (state: any) => state.User.hasProfile;
export const userDetails = (state: any) => state.User.userData;

export default userDataSlice.reducer;
