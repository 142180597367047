import PasswordValidator from "password-validator";


const getPasswordSchema = () => {
  const schema = new PasswordValidator();
  schema
    .has()
    .uppercase(1)
    .has()
    .lowercase(1)
    .has()
    .digits(1)
    .has()
    .not()
    .spaces()
    .has()
    .symbols(1);

  return schema;
}

export default getPasswordSchema;
