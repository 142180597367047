import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import passwordValidator from "password-validator";

import { useQuery } from "../../hooks/use-query";
import { signUp } from "../../services/signup.service";

import { useSelector } from "react-redux";
import { customToast } from "../shared-components/custom-toast";
import { AuthLayout } from "./login.layout";
import { userDetails } from "../../redux/slices/userDataSlice"; 



// Define the interface for the form data
interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  user_types: string;
}

export const AddNewUserPage = () => {
  const userData = useSelector(userDetails);
  // Initialize an array to store permission names
  const permissionName: string[] = []; // Explicitly define the type as 'string[]'

  // Iterate through the user's groups to extract permission names
  userData.groups.forEach((group: { permissions: any; name: string }) => {
  // Loop through each permission within the group
  group.permissions.forEach((permission: any) => {
    // Extract the codename of the permission
    const permissionCodename = permission.codename;
    
    // Add the extracted codename to the permissionName array
    permissionName.push(permissionCodename);
  });
});

  const navigate = useNavigate();
  const query = useQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: Array.isArray(query.email)
        ? query.email[0] || ""
        : query.email || "",
      password: "",
      confirm_password: "",
      user_types: "",
    },
  });
  const optionsSubUserType1 = [
    { value: "is_financier_admin", label: "Financier-Admin" },
    { value: "is_financier_general", label: "Financier-General" },
  ];
  const optionsSubUserType2 = [
    { value: "is_broker_admin", label: "Broker-Admin" },
    { value: "is_broker_general", label: "Broker-General" },
  ];

  const schema = new passwordValidator();
  schema
    .has()
    .uppercase(1)
    .has()
    .lowercase(1)
    .has()
    .digits(1)
    .has()
    .not()
    .spaces()
    .has()
    .symbols(1);

  const successCallback = useCallback(
    (res: any) => {
      customToast({
        message: `Sent Verification Link on ${res.data.data.email}`,
        type: "success",
      });
      setIsLoading(false);
      navigate("/login", { replace: true });
    },
    [navigate]
  );

  const errorCallback = useCallback((error: any) => {
    setIsLoading(false);
    setAddUserError(
      error.response.data.errors.email?.[0] || "Something went wrong"
    );
  }, []);

  const validateForm = () => {
    return (
      watch("first_name").length &&
      watch("last_name").length &&
      watch("email").length &&
      watch("password").length
    );
  };
  const [addUserError, setAddUserError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onAddUser = (credentials: FormData) => {
    setIsLoading(true);
    setAddUserError("");
    const result = schema.validate(credentials.password, { list: true });
    if (Object.keys(result).length !== 0) {
      setAddUserError(
        "Password must have at least 1 Uppercase, 1 Smallercase, 1 Special, 1 Digit character and No Space is allowed"
      );
      setIsLoading(false);
      return;
    }
    signUp(credentials).then(successCallback).catch(errorCallback);
  };

  return (
    <AuthLayout title="Add User - quick &amp; easy" isLoading={isLoading}>
      <form onSubmit={handleSubmit(onAddUser)}>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="first_name">
            First Name
          </label>
          <input
            type="text"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="First Name"
            {...register("first_name", {
              required: "This input is required.",
              maxLength: {
                value: 254,
                message: "This input can't exceed 254 characters",
              },
            })}
          />
          {errors.first_name?.message ? (
            <div className="px-3 text-red-400">
              {errors.first_name?.message}
            </div>
          ) : (
            <></>
          )}
        </fieldset>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="last_name">
            Last Name
          </label>
          <input
            type="text"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Last Name"
            {...register("last_name", {
              required: "This input is required.",
              maxLength: {
                value: 254,
                message: "This input can't exceed 254 characters",
              },
            })}
          />
          {errors.last_name?.message ? (
            <div className="px-3 text-red-400">{errors.last_name?.message}</div>
          ) : (
            <></>
          )}
        </fieldset>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Email"
            {...register("email", {
              required: "This input is required.",
              maxLength: {
                value: 254,
                message: "This input can't exceed 254 characters",
              },
            })}
          />
          {errors.email?.message ? (
            <div className="px-3 text-red-400">{errors.email?.message}</div>
          ) : (
            <></>
          )}
        </fieldset>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Password"
            {...register("password", {
              required: "This input is required.",
              maxLength: {
                value: 68,
                message: "This input can't exceed 254 characters",
              },
              minLength: {
                value: 6,
                message: "This input requires at least 6 characters",
              },
            })}
          />
          {errors.password?.message ? (
            <div className="px-3 text-red-400">{errors.password?.message}</div>
          ) : (
            <></>
          )}
        </fieldset>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="confirm_password">
            Confirm Password
          </label>
          <input
            type="password"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Confirm Password"
            {...register("confirm_password", {
              required: "This input is required.",
              validate: (val: string) => {
                if (watch("password") !== val) {
                  return "Passwords do not match";
                }
              },
            })}
          />
          {errors.confirm_password?.message ? (
            <div className="px-3 text-red-400">
              {errors.confirm_password?.message}
            </div>
          ) : (
            <></>
          )}
        </fieldset>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="user_types">
            User Type
          </label>
          {(permissionName.includes("add_customuser") && permissionName.includes("add_company"))  
          && <select
          className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
          {...register("user_types", {
            required: "Please select a user type.",
          })}
        >
          <option value="">Select User Type</option>
          {optionsSubUserType1.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        }
          
          {(permissionName.includes("add_customuser") && !permissionName.includes("add_company"))  
          && <select
          className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
          {...register("user_types", {
            required: "Please select a user type.",
          })}
        >
          <option value="">Select User Type</option>
          {optionsSubUserType2.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        }
          {errors.user_types && (
            <div className="px-3 text-red-400">{errors.user_types.message}</div>
          )}
        </fieldset>
        <fieldset className="mb-6">
          <input
            className="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
            disabled={!validateForm() || isLoading}
            type="submit"
            value={isLoading ? "Please wait..." : "Add User"}
          />
          <div
            style={{ wordBreak: "keep-all" }}
            className={`bg-red-100 border border-red-400 text-red-600 text-center text-sm px-4 py-3 rounded mt-4  ${
            addUserError ? "" : "hidden"
            }`}
          >
            {addUserError}
          </div>
        </fieldset>
      </form>
    </AuthLayout>
  );

};
