import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
interface Props {
  setValue: any;
  watch: any;
  reset: boolean;
  setReset: any;
}

const Advancearrears: React.FC<Props> = ({
  setValue,
  watch,
  reset,
  setReset,
}) => {
  const [currentState, setCurrentState] = useState(watch("advance_arrears"));
  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
  useEffect(() => {
    setCurrentState(watch("advance_arrears"));
    if (reset) {
      setValue("advance_arrears", "advance");
      setCurrentState("advance");
    }
  }, [reset, watch("advance_arrears")]);
  return (
    <>
      <div className={`h-full items-center py-4 max-w-[372px] pr-2 ${!isMobile ? "w-[15vw]": "w-full"}`}>
        <div className=" flex flex-row border-2 border-[#4b3b95] rounded-3xl h-[80%] p-1 mr-[1%]">
          <div
            className={`text-white ${
              currentState === "advance" && "bg-[#514298]"
            } rounded-3xl p-2 flex justify-center cursor-default text-base pl-4 pr-4 w-1/2`}
            onClick={() => {setReset(false);setCurrentState("advance");setValue("advance_arrears", "advance");}}
          >
            Advance
          </div>
          <div
            className={`text-white ${
              currentState === "arrears" && "bg-[#514298]"
            } rounded-3xl p-2 flex justify-center text-base cursor-default pl-4 pr-4 w-1/2`}
            onClick={() => {setReset(false);setCurrentState("arrears"); setValue("advance_arrears", "arrears");}}
          >
            Arrears
          </div>
        </div>
      </div>
    </>
  );
};

export default Advancearrears;
