import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as CrossFilled } from "../../../../icons/cross_filled.svg";
import { ReactComponent as CrossFilledWhite } from "../../../../icons/cross_filled_white.svg";
import { ReactComponent as TickFilled } from "../../../../icons/tick_filled.svg";
import { ReactComponent as TickFilledWhite } from "../../../../icons/tick_filled_white.svg";
import { ReactComponent as NotSure } from "../../../../icons/not_sure_filled.svg";
import { ReactComponent as NotSureWhite } from "../../../../icons/not_sure_white.svg";
import { useMediaQuery } from "react-responsive";

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  title: string;
  name: string;
  id: string;
  setvalue: any;
  currentState: any;
  setState: any;
  cancelChanges: any;
  formData: any;
  refreshState: any;
  setRefreshState: any;
}

const CustomDropdown: React.FC<Props> = ({
  options,
  title,
  name,
  setvalue,
  cancelChanges,
  formData,
  refreshState,
  setRefreshState,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isDesktopScreen = useMediaQuery({ query: "(max-width: 1570px)" });

  useEffect(() => {
    // Function to handle clicks outside the dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    // Attach the click event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option: Option) => {
    setSelectedOption(option);
    setRefreshState(!refreshState);
    setIsOpen(false);
    if (option.label === "Yes") {
      setvalue(name, true);
    } else if(option.label === "No") {
      setvalue(name, false);
    }
    else{
      setvalue(name, null);
    }
  };

  useEffect(() => {
    const dropDownOptions = [
      { value: "option1", label: "Not Sure" },
      { value: "option2", label: "Yes" },
      { value: "option3", label: "No" },
    ];
    if (name === "sale_hire_back") {
      if (formData.sale_hire_back === true) {
        setSelectedOption(dropDownOptions[1]);
      } else if (formData.sale_hire_back === null) {
        setSelectedOption(dropDownOptions[0]);
      } else {
        setSelectedOption(dropDownOptions[2]);
      }
    } else if (name === "private_sale") {
      if (formData.private_sale === true) {
        setSelectedOption(dropDownOptions[1]);
      } else if (formData.private_sale === null) {
        setSelectedOption(dropDownOptions[0]);
      } else {
        setSelectedOption(dropDownOptions[2]);
      }
    } else if (name === "doc_fee_financed") {
      if (formData.doc_fee_financed === true) {
        setSelectedOption(dropDownOptions[1]);
      } else if (formData.doc_fee_financed === null) {
        setSelectedOption(dropDownOptions[0]);
      } else {
        setSelectedOption(dropDownOptions[2]);
      }
    } else {
      if (formData.asset_owner === true) {
        setSelectedOption(dropDownOptions[1]);
      } else if (formData.asset_owner === null) {
        setSelectedOption(dropDownOptions[0]);
      } else {
        setSelectedOption(dropDownOptions[2]);
      }
    }
  }, [cancelChanges, name, formData]);

  return (
    <div className="relative mx-1" ref={dropdownRef}>
      <div
        className={`${
          !isOpen
            ? ` bg-[#3e2d8d] border-[3px] border-[#514298] ${
                selectedOption &&
                selectedOption.label !== "Not Sure" &&
                "bg-[#514298]"
              }`
            : "bg-white border-[3px] border-[#fdd57c]"
        } rounded-lg px-2 py-2 flex items-center justify-between w-full min-h-10 cursor-pointer`}
        onClick={handleToggle}
      >
        <span
          className={`${
            !isOpen ? "text-white" : "text-black"
          } flex flex-row items-center ${
            isDesktopScreen && "text-base"
          } text-base`}
        >
          {selectedOption?.label === "Yes" ? (
            !isOpen ? (
              <TickFilledWhite className="mr-2" />
            ) : (
              <TickFilled className="mr-2" />
            )
          ) : (
            <></>
          )}
          {selectedOption?.label === "No" ? (
            !isOpen ? (
              <CrossFilledWhite className="mr-2" />
            ) : (
              <CrossFilled className="mr-2" />
            )
          ) : (
            <></>
          )}
          {selectedOption?.label === "Not Sure" ? (
            !isOpen ? (
              <NotSureWhite className="mr-2" />
            ) : (
              <NotSure className="mr-2" />
            )
          ) : (
            <></>
          )}
          {!selectedOption ? (
            isOpen ? (
              <NotSure className="mr-2" />
            ) : (
              <NotSureWhite className="mr-2" />
            )
          ) : (
            <></>
          )}
          {title}
        </span>
      </div>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border mt-1 border-gray-300 rounded-md">
          {options.map((option, index) => (
            <React.Fragment key={option.value}>
              <li
                className="my-1 cursor-pointer flex font-bold text-base text-black flex-row px-3 items-center mx-1 py-3 hover:bg-[#fbca5d] hover:rounded"
                onClick={() => handleOptionSelect(option)}
              >
                {option?.label === "Yes" && <TickFilled className="mr-2" />}
                {option?.label === "No" && <CrossFilled className="mr-2" />}
                {option?.label === "Not Sure" && (
                  <NotSure className="mr-2" />
                )}{" "}
                {option.label}
              </li>
              {index !== options.length - 1 && (
                <div className="border-b border-black opacity-10 mx-5" />
              )}
            </React.Fragment>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
