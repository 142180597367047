import {default as axios} from "axios";
import {FORGOT_PASSWORD_URL} from "../api.routes";

export interface ForgotPasswordPayload {
  email: string
}

export interface ForgotPasswordResponse {
  message: string
  status: number
}

const forgotPasswordService = async (payload: ForgotPasswordPayload) => {
  return await axios.post<ForgotPasswordResponse>(FORGOT_PASSWORD_URL, payload);
}

export default forgotPasswordService;

