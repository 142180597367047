import { default as axios } from "axios";
import { COMPANY_API_URL } from "../api.routes";
import { authHeader } from "../auth.header";

export async function getCompanies() {
  return await axios.get(COMPANY_API_URL, {
    headers: authHeader(),
  });
}

export async function getCompany(company_id: number) {
  return await axios.get(COMPANY_API_URL + company_id, {
    headers: authHeader(),
  });
}

export async function createCompany(params: object) {
  return await axios.post(COMPANY_API_URL, params, {
    headers: authHeader(),
  });
}

export async function updateCompanyData(params: any) {
  return await axios.patch(COMPANY_API_URL + params.company_id + "/", params, {
    headers: authHeader(),
  });
}

export async function deleteCompany(company_id:number){
  return await axios.delete(COMPANY_API_URL + company_id + "/", {
    headers: authHeader(),
  });
}