import { AppLayout } from "../app-layout";
import { CompanyFilter } from "./directory-components/company.filter";
import { ProductsFilter } from "./directory-components/products.filter";
import { useSelector } from "react-redux";
import { directoryState as ds } from "../../../redux/slices/directorySlice";
import { BracketFilter } from "./directory-components/bracket.filter";
import { UpliftFilter } from "./directory-components/uplift.filter";
import { ReactComponent as DotFilled } from "../../../icons/dot_filled.svg";
import Loader from "../calculator/loader";
import { useEffect, useState } from "react";

export const DirectoryPage = () => {
  const directoryState = useSelector(ds);
  const [currentWidth, setCurrentWidth] = useState(0);
  return (
    <AppLayout title="Directory">
      <CompanyFilter setCurrentWidth={setCurrentWidth}/>
      {directoryState.orgLoading && <Loader currentWidth={currentWidth}/>}
      {directoryState.selectedComapnyId !== -1 && <ProductsFilter />}
      {directoryState.productLoading && <Loader currentWidth={currentWidth}/>}
      {directoryState.productsData && <div className="flex justify-center mt-[2%]"><DotFilled /></div>}
      {directoryState.productsData && <BracketFilter />}
      {directoryState.bracketLoading && <Loader currentWidth={currentWidth}/>}
      {directoryState.bracketData && <div className="flex justify-center mt-[2%]"><DotFilled /></div>}
      {directoryState.bracketData && <UpliftFilter />}
      {directoryState.upliftLoading && <Loader currentWidth={currentWidth}/>}
    </AppLayout>
  );
};
