import React, { MouseEventHandler, FC } from "react";
import { ReactComponent as LockedIcon } from "../../../assets/icons/lock-8.svg";

import { NavLink, useLocation } from "react-router-dom";

const styleMarginLeftAuto = { marginLeft: "auto" };

type SidebarLinkType = {
  to: string;
  text: string;
  isLocked?: boolean;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  onClick?: MouseEventHandler;
};

const SidebarLink: FC<SidebarLinkType> = ({
  to,
  text,
  isLocked = false,
  icon,
  activeIcon,
  onClick,
}) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <NavLink
      to={to}
      className={`link ${
        isActive
          ? "link--active text-black font-bold ml-[10%] bg-gray-600 bg-opacity-10 rounded-full w-fit"
          : "text-black ml-[10%]"
      } ${
        isLocked ? "pointer-events-none" : ""
      } flex px-6 py-2 text-[14px] tracking-wide mb-3 mx-2 last:mb-0 items-center`}
      onClick={onClick}
    >
      <div className="link__icon w-5 mr-4">{!isActive ? activeIcon : icon}</div>
      <span className={`${isLocked ? "opacity-50" : ""}`}>{text}</span>
      {isLocked && (
        <div className="link__icon w-4 h-4" style={styleMarginLeftAuto}>
          <LockedIcon />
        </div>
      )}
    </NavLink>
  );
};

export default SidebarLink;