import React from "react";

import { Illustration } from "./illustration";
import { MarketingLayout } from "./marketing-layout";

export const AboutPage = () => {
  return (
    <MarketingLayout
      title="Welcome to Finput"
      subtitle="If you have questions, please reach out to us :)"
    >
      <section className="py-20 bg-gray-200">
        <div className="mx-auto max-w-2xl px-4">
          <div className="flex flex-wrap justify-center">
            <div className="px-4">
              <div className="bg-white rounded border border-gray-400 mb-6">
                <div className="flex-auto p-6 lg:p-12 text-center text-base md:text-xl leading-loose">
                  <div className="mt-6 md:mt-10">
                    If you have any questions, please{" "}
                    <a
                      href="mailto:support@expa.com.au"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                    >
                      email us
                    </a>{" "}
                    and we will do our best to respond to you promptly.
                  </div>
                </div>
                <Illustration icon="travel" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </MarketingLayout>
  );
};
