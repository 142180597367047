import React, { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { MarketingLayout } from "./marketing-layout";

export const TermsPage = () => {
  return (
    <MarketingLayout title="Terms of use" subtitle="Updated: 01 May 2020">
      <section className="py-10 lg:py-20 bg-gray-200">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center text-justify px-4">
            <TermsSection title="Introduction">
              <div className="mt-5">
                The following document (“Terms of use”, “Use Agreement” or
                “Agreement”) governs the use of Expa Service (the
                “Service”), including, without limitation, the mobile or PWA
                application (the “App”), for the creation of travel trips and/or
                itineraries, the use of travel monitoring and alerts, and any
                additional services, whether free or provided on a paid
                subscription basis, as provided or made available to individuals
                by Expa (“Expa” or “us” or “we”). Please read the
                terms and conditions contained in this Agreement carefully. Your
                use of and/or registration with the Service will constitute your
                ongoing acceptance of this Agreement. If you cannot accept this
                Agreement, please do not use the Service.
              </div>
              <div className="mt-5">
                Expa reserves the right to modify or replace this Agreement
                at any time and at our sole discretion. Concur will indicate at
                the top of this Agreement the date it was last updated. Any
                changes will be effective immediately upon posting the revised
                version (or such later effective date as may be indicated at the
                top of the revised Agreement). It is your responsibility to
                regularly check the Service to determine if there have been
                changes to this Agreement and to review such changes. Your
                continued use of the Service following the posting or effective
                date of any changes to the Agreement will constitute your
                acceptance of any such changes. If you do not agree to the
                changes, you must stop using the Service.
              </div>
              <div className="mt-5">
                Your failure to abide by this Agreement or any other terms or
                conditions posted anywhere within the Service may result in
                suspension or termination of your access to the Service, without
                notice, in addition to our other remedies.
              </div>
              <div className="mt-5">
                Important Note: If you are a user located in the United States,
                this Agreement contains an arbitration provision and class
                action waiver, which affects your rights under this Agreement
                and with respect to any dispute you may have with Expa. You
                may opt out of the binding individual arbitration and class
                action waiver as provided below.
              </div>
            </TermsSection>

            <TermsSection title="1. Registration and account creation">
              <div className="mt-5">
                <ol className="list-roman list-outside pl-5">
                  <li className="mt-4">
                    <div className="font-bold">Registration information</div>
                    <p className="mt-2">
                      Expa may at times require that you register and/or
                      set up an account to use all or a portion of the Service.
                      You may be provided, or required to choose, a password,
                      ID/username, and/or other registration information, such
                      as credit card information for elective services made
                      available on a paid subscription basis (collectively, the
                      “Registration Information”). You agree and represent that
                      all Registration Information provided by you is accurate
                      and up-to-date. If any of your Registration Information
                      changes, you must immediately update it by editing your
                      profile on the Service. In our sole discretion, we may
                      refuse to grant you a username that impersonates someone
                      else, is protected by trademark or other proprietary right
                      law, or is offensive.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">
                      Maintenance of registration information
                    </div>
                    <p className="mt-2">
                      If you register and/or set up an account on the Service,
                      you are solely responsible for maintaining the
                      confidentiality of your Registration Information, and for
                      any failure to do so. You are solely responsible for all
                      use of the Service by you and anyone you allow to access
                      the Service. You may not sub-license, transfer, sell or
                      assign your Registration Information and/or this Agreement
                      to any third party without Expa’s written approval.
                      Any attempt to do so will be null and void and shall be
                      considered a material breach of this Agreement.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">
                      Security; Mobile use precautions
                    </div>
                    <p className="mt-2">
                      If you have reason to believe that your account is no
                      longer secure (e.g., loss, theft or unauthorized
                      disclosure or use of your Registration Information or
                      computer or mobile device used to access the Service), you
                      must promptly change the affected Registration Information
                      and notify Expa via{" "}
                      <a
                        href="mailto:support@expa.com.au"
                        className="text-primary"
                      >
                        email
                      </a>
                      .
                    </p>
                    <p className="mt-2">
                      If you elect to receive messages or other communications
                      from the Service directly to your mobile device, you are
                      solely responsible for keeping the Service updated with
                      your current phone number. Expa shall not be liable
                      for information sent to a device that is associated with
                      your outdated mobile phone number. If you install any
                      software or enable any service that stores information
                      from the Service on any mobile device or computer, it is
                      your responsibility, prior to transfer of such device, to
                      remove your information or otherwise disable access to
                      such software or service, to prevent unauthorized access
                      to your information or account.
                    </p>
                  </li>
                </ol>
              </div>
            </TermsSection>

            <TermsSection title="2. Use of the Service">
              <div className="mt-5">
                <ol className="list-roman list-outside pl-5">
                  <li className="mt-4">
                    <div className="font-bold">
                      Personal and lawful use only
                    </div>
                    <p className="mt-2">
                      Unless otherwise specified, Expa grants you a limited
                      right to use the Service for your personal, noncommercial
                      use only. You agree to use the Service only for lawful
                      purposes and you acknowledge that your failure to do so
                      may subject you to civil and criminal liability.
                    </p>
                    <p className="mt-2">
                      You will not do, nor attempt to do, any of the following
                      with respect to the Service, except as expressly
                      authorized in writing or otherwise enabled by Expa:
                    </p>
                    <ol className="list-alpha list-outside pl-5">
                      <li className="mt-2">
                        access the Service in a manner or through an interface
                        not provided or authorized by Expa, including but
                        not limited to, any automated means (e.g., scripts or
                        bots);
                      </li>
                      <li className="mt-2">
                        reproduce, duplicate, copy, sell, trade, resell or
                        exploit the Service; and
                      </li>
                      <li className="mt-2">
                        republish or syndicate the information available on the
                        Service (including but not limited to information made
                        accessible to non-paying users via any portion of the
                        Service provided on a paid subscription basis).
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Use by children</div>
                    <p className="mt-2">
                      The Service is not intended for users under the age of 18,
                      and Expa does not knowingly collect personally
                      identifiable information from or about individuals under
                      the age of 18, and users are expressly prohibited from
                      submitting personally identifiable information about
                      individuals under 18 to us; any such information submitted
                      by users will not knowingly be used, posted, or retained
                      by us.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">User conduct</div>
                    <p className="mt-2">
                      You agree not to use the Service to upload, post,
                      transmit, share, store or otherwise make available any
                      content that we deem to be harmful, threatening, unlawful,
                      defamatory, infringing, abusive, inflammatory, harassing,
                      vulgar, obscene, fraudulent, invasive of privacy or
                      publicity rights, hateful, or racially, ethnically or
                      otherwise objectionable. You further agree not to upload,
                      post, transmit, share or otherwise make available any
                      unsolicited or unauthorized advertising, solicitations,
                      promotional materials, “junk mail,” “spam,” “chain
                      letters,” “pyramid schemes,” or any other form of
                      solicitation.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Privacy policy</div>
                    <p className="mt-2">
                      We may collect registration, payment, and other
                      information about you through the Service. Our collection
                      and use of this information is described in our{" "}
                      <Link to="/privacy" className="text-primary">
                        Privacy policy
                      </Link>
                      . You agree to our data practices, including the
                      collection, use, transmission and disclosure of your
                      information as described in our Privacy policy, as well as
                      the transfer and processing of your information to the
                      United States and other countries, regardless of where you
                      use the Service. You acknowledge that the laws,
                      regulations, and standards of the country in which your
                      information is stored or processed may be different from
                      those of your own country.
                    </p>
                  </li>
                </ol>
              </div>
            </TermsSection>

            <TermsSection title="3. Intellectual property">
              <div className="mt-5">
                <ol className="list-roman list-outside pl-5">
                  <li className="mt-4">
                    <div className="font-bold">Use of material</div>
                    <p className="mt-2">
                      The Service contains material that is protected by state,
                      national and international copyright, trademark and other
                      intellectual property laws. You may not modify, copy,
                      reproduce, republish, upload, post, transmit or distribute
                      in any way any material, including code and software, from
                      the Service. You may download material from the Service
                      and may use the Service for your personal, non-commercial
                      use only, provided you keep intact all copyright and other
                      proprietary notices.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Ownership and control</div>
                    <p className="mt-2">
                      The content, organization, graphics, text, images, video,
                      design, compilation, advertising and all other material on
                      the Service, including without limitation, the “look and
                      feel” of this website and App, are protected under
                      applicable copyrights and other proprietary (including but
                      not limited to intellectual property) rights and are the
                      property of Expa or its licensors. The copying,
                      rearrangement, redistribution, modification, use or
                      publication by you, directly or indirectly, of any such
                      matters or any part of the website, including but not
                      limited to the removal or alteration of advertising,
                      except for the limited rights of use granted hereunder, is
                      strictly prohibited.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">
                      Compliance with laws and agreement
                    </div>
                    <p className="mt-2">
                      You agree to use the Service only for purposes that are
                      permitted by any applicable law, rules or regulations, and
                      as permitted by this Agreement.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Interference</div>
                    <p className="mt-2">
                      You agree not to disrupt, overwhelm, attack, modify or
                      interfere with the Service or its associated software,
                      hardware and/or servers in any way, and you agree not to
                      impede or interfere with others’ use of the Service. You
                      further agree not to alter or tamper with any information
                      or materials on or associated with the Service. Other than
                      connecting to Expa’s servers by http requests using a
                      Web browser or through the App, you may not attempt to
                      gain access to Expa’s servers by any means,
                      including, without limitation, by using administrator
                      passwords or by masquerading as an administrator while
                      using the Service or otherwise.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Linking to other sites</div>
                    <p className="mt-2">
                      You acknowledge that Expa has not reviewed and does
                      not endorse the content of all sites linked to from this
                      Service and is not responsible for the content or actions
                      of any other sites linked to or from this Service. Your
                      linking to any other service or site is at your sole risk.
                      By linking this Service to another service or site, you
                      expressly agree to the disclosure of User Content
                      permitted by or required to support such linking. You
                      acknowledge that the Service may include advertisements
                      and that Expa is not responsible for the content of
                      such advertisements.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Export compliance</div>
                    <p className="mt-2">
                      Expa and the Services are subject to the export laws
                      of various countries including, without limitation, those
                      of the European Union and its member states, and of the
                      United States. You acknowledge that, pursuant to the
                      applicable export laws, trade sanctions, and embargoes
                      issued by these countries, Expa is required to take
                      measures to prevent entities, organizations, and parties
                      listed on government-issued sanctioned-party lists from
                      accessing certain products, technologies, and services
                      through Expa website or the App. This may include (a)
                      automated checks of any Registration Information as set
                      out herein and other information a user provides about his
                      or her identity against applicable sanctioned-party lists;
                      (b) regular repetition of such checks whenever a
                      sanctioned-party list is updated or when a user updates
                      his or her information; (c) blocking of access to the
                      Service and Expa systems in case of a potential
                      match; and (d) contacting a user to confirm his or her
                      identity in case of a potential match.
                    </p>
                  </li>
                </ol>
              </div>
            </TermsSection>

            <TermsSection title="4. User content">
              <div className="mt-5">
                <ol className="list-roman list-outside pl-5">
                  <li className="mt-4">
                    <div className="font-bold">User responsibility</div>
                    <p className="mt-2">
                      You are solely responsible for the photos, profiles
                      (including your name, image, and likeness), reviews,
                      messages, notes, text, and other content that you upload,
                      publish or display (hereinafter, “post”) on or through the
                      Service, or transmit to or share with other users
                      (collectively the “User Content”). You may not post,
                      transmit, or share User Content on the Service that you
                      did not create or that you do not have permission to post.
                      You understand and agree that Expa may, but is not
                      obligated to, review the Service and may delete or remove
                      (without notice) any site content or User Content in its
                      sole discretion, for any reason or no reason, including
                      User Content that in the sole judgment of the Company
                      violates this Agreement, or which might be offensive,
                      illegal, or that might violate the rights, harm, or
                      threaten the safety of users or others. You are solely
                      responsible at your sole cost and expense for creating
                      backup copies and replacing any User Content you post or
                      store on the Service or provide to Expa.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Content license</div>
                    <p className="mt-2">
                      When you post User Content to the Service, you authorize
                      and direct us to use and disclose the User Content as
                      necessary to provide the Service and to make such copies
                      thereof as we deem necessary to facilitate the
                      transmission, posting and storage of the User Content on
                      the Service. By posting User Content to any part of the
                      Service, you automatically grant, and you represent and
                      warrant that you have the right to grant to Expa and
                      all other users an irrevocable, perpetual, non-exclusive,
                      transferable, fully paid, worldwide license (with the
                      right to freely sublicense) to use, copy, modify, publicly
                      perform, publicly display, reformat, translate, syndicate,
                      republish, excerpt (in whole or in part) and distribute
                      such User Content for any purpose, commercial,
                      advertising, or otherwise, on or in connection with the
                      Service or the promotion thereof, and to prepare
                      derivative works of, or incorporate into other works, such
                      User Content. You may remove your User Content from the
                      Service at any time, but you acknowledge that Expa
                      may not be able to and has no obligation to restrict any
                      use by any other person, including other users, and the
                      license that you have granted remains in effect.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Ownership</div>
                    <p className="mt-2">
                      We do not assert any ownership over your User Content;
                      rather, as between us and you, subject to the rights
                      granted to us in this Agreement and the terms of Section
                      4(iv), you retain full ownership of all of your User
                      Content and any intellectual property rights or other
                      proprietary rights associated with your User Content. You
                      represent, warrant and agree that no materials of any kind
                      submitted through your account or otherwise posted,
                      transmitted, or shared by you on or through the Service
                      will violate or infringe upon the rights of any third
                      party, including copyright, trademark, privacy, publicity
                      or other personal or proprietary rights; or contain
                      libelous, defamatory or otherwise unlawful material.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Feedback</div>
                    <p className="mt-2">
                      Notwithstanding Section 4(iii), if you provide Expa
                      with feedback, suggestions, testimonials, comments, ideas,
                      ratings, reviews, bug reports, or any similar or related
                      information (“Feedback”), you agree that (a) any and all
                      right, title and interest to such Feedback shall become
                      the exclusive property of Expa, (b) Expa shall
                      be able to use and share such Feedback without your
                      consent, only if Expa does not use your first and
                      last name, (c) if you give prior consent, Expa shall
                      be able to use and share such Feedback while including
                      your first and last name, (d) Expa owes you no
                      obligation or compensation whatsoever regarding the
                      Feedback, and (e) Expa may already have contemplated
                      or be contemplating the same or similar ideas as your
                      Feedback. If you do not agree with these terms regarding
                      Feedback, or want to maintain ownership of any
                      intellectual property contained in any specific Feedback,
                      your sole option and recourse is to not submit such
                      specific Feedback to Expa.
                    </p>
                  </li>
                </ol>
              </div>
            </TermsSection>

            <TermsSection title="5. Fees and payments for paid accounts">
              <div className="mt-5">
                <ol className="list-roman list-outside pl-5">
                  <li className="mt-4">
                    <div className="font-bold">
                      Premium service subscriptions
                    </div>
                    <p className="mt-2">
                      As used in this Section 5, “Premium Service” means any
                      service made available as part of the Service on a paid
                      subscription basis. If you subscribe to any Premium
                      Service, you agree to pay any and all applicable fees
                      (e.g., recurring monthly or annual subscription fees)
                      incurred in connection with your account at the rates in
                      effect when the charges were incurred. EXPA WILL
                      AUTOMATICALLY CHARGE YOUR CREDIT CARD AT THE BEGINNING OF
                      THE BILLING PERIOD, AND BILLING WILL RECUR AUTOMATICALLY
                      AT THE INTERVAL YOU CHOSE AT SIGNUP UNTIL YOU CHANGE OR
                      TERMINATE YOUR ACCOUNT. Any change in the chosen payment
                      method will go into effect for the next billing period. If
                      you change your account to a type that does not require a
                      paid subscription, you will retain access to the features
                      for which you have already paid until the end of the
                      current billing period. In contrast, if you terminate your
                      account, such termination will be effective immediately,
                      and you will not receive a refund for any amounts you
                      already paid. You agree to pay any applicable taxes, and
                      reimburse us for any collection costs and interest for any
                      overdue amounts.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">
                      Premium service free trial offers
                    </div>
                    <p className="mt-2">
                      If you receive a Premium Service free trial offer for a
                      specific number of days, you will not be charged during
                      the free trial time period if you elect to create an
                      account for such Premium Service. YOU WILL BE
                      AUTOMATICALLY CHARGED IMMEDIATELY FOLLOWING THE END OF
                      YOUR FREE TRIAL PERIOD FOR THE SUBSEQUENT PERIOD, IN
                      ADVANCE, IN ACCORDANCE WITH THE “PREMIUM SERVICE
                      SUBSCRIPTIONS” TERMS STATED ABOVE IN SECTION 5(I). YOU
                      WILL CONTINUE TO BE AUTOMATICALLY CHARGED AT THE REGULAR
                      INTERVAL APPLICABLE TO THE SERVICE YOU HAVE CHOSEN (E.G.,
                      ANNUALLY OR MONTHLY) UNTIL YOU DECIDE TO CANCEL. You are
                      limited to one free trial per Premium Service.
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">Cancellation; No refunds</div>
                    <p className="mt-2">
                      You can cancel your Premium Service at any time during the
                      applicable subscription period, and such cancellation of
                      Premium Service will be effectively immediately, but all
                      fees and charges prior to your cancellation are
                      nonrefundable. To cancel a free trial offer, you must
                      cancel the Service prior to the end of the free trial
                      offer period. Please make a note of when your free trial
                      will expire as you may not receive additional notice prior
                      to the automatic charge if you do not cancel before the
                      free trial period expires. If you cancel a Premium Service
                      after you have already registered under a free trial offer
                      for the Premium Service and attempt to register for an
                      additional free trial of the same Premium Service,
                      Expa will automatically charge your credit card in
                      accordance with the “Premium Service Subscriptions” terms
                      stated above in Section 5(i). IF YOU DO NOT WISH TO PAY
                      THE APPLICABLE FEES FOR A PREMIUM SERVICE, YOU SHOULD
                      CANCEL YOUR ACCOUNT BEFORE THE FREE TRIAL PERIOD ENDS AND
                      NOT COMPLETE ANY SUBSEQUENT REGISTRATION FOR THAT PREMIUM
                      SERVICE. Cancellations may be made within the settings
                      menu in the online version of the Service and following
                      the prompts. If you do not have access to the browser
                      version of the Service, you may also cancel the Premium
                      Service via{" "}
                      <a
                        href="mailto:support@expa.com.au"
                        className="text-primary"
                      >
                        email
                      </a>
                      .
                    </p>
                  </li>
                  <li className="mt-4">
                    <div className="font-bold">
                      Third party charges and mobile alerts
                    </div>
                    <p className="mt-2">
                      You are solely responsible for any fees or charges
                      incurred to access the Service through an Internet access
                      provider or other third party, including without
                      limitation charges incurred to receive SMS messages or
                      other mobile access, which may be billed to You or
                      deducted from Your prepaid balance by Your mobile
                      provider. You agree that Expa is not liable in any
                      way for any third-party charges.
                    </p>
                  </li>
                </ol>
              </div>
            </TermsSection>

            <TermsSection title="6. Copyright complaints">
              <div className="mt-5">
                We respect the intellectual property rights of others and we
                prohibit users from uploading, posting or otherwise transmitting
                on the Service any materials that violate another party’s
                intellectual property rights. If you believe that any material
                on the Service infringes upon any copyright which you own or
                control, in accordance with the Digital Millennium Copyright
                Act, you may send such infringement to us via{" "}
                <a href="mailto:support@expa.com.au" className="text-primary">
                  email
                </a>
                .
              </div>
            </TermsSection>

            <TermsSection title="7. Indemnification">
              <div className="mt-5">
                You agree to indemnify Expa and its affiliates, employees,
                agents, representatives and third-party Service Providers, and
                to defend and hold each of them harmless, from any and all
                claims and liabilities (including attorneys fees) which may
                arise from your submissions, from your unauthorized use of
                material obtained through the Service, or from your breach of
                this Agreement, or from any such acts through your use of the
                Service.
              </div>
            </TermsSection>

            <TermsSection title="8. Liability disclaimer and limitation of liability">
              <div className="mt-5">
                YOU ACKNOWLEDGE THAT YOU ARE USING THE SERVICE AT YOUR OWN RISK.
                THE SERVICE IS PROVIDED “AS IS”. TO THE MAXIMUM EXTENT PERMITTED
                BY APPLICABLE LAW EXPA, ITS AFFILIATES AND ITS THIRD PARTY
                SERVICE PROVIDERS HEREBY EXPRESSLY DISCLAIM ANY AND ALL
                WARRANTIES, EXPRESS AND IMPLIED, INCLUDING BUT NOT LIMITED TO
                ANY WARRANTIES OF ACCURACY, RELIABILITY, TITLE, MERCHANTABILITY,
                NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE OR ANY OTHER
                WARRANTY, CONDITION, GUARANTEE OR REPRESENTATION, WHETHER ORAL,
                IN WRITING OR IN ELECTRONIC FORM. EXPA, ITS AFFILIATES, AND
                ITS THIRD-PARTY SERVICE PROVIDERS DO NOT REPRESENT OR WARRANT
                THAT ACCESS TO THE SERVICE WILL BE UNINTERRUPTED OR THAT THERE
                WILL BE NO FAILURES, ERRORS OR OMISSIONS OR LOSS OF TRANSMITTED
                INFORMATION, OR THAT NO VIRUSES WILL BE TRANSMITTED ON THE
                SERVICE.
              </div>
              <div className="mt-5">
                EXPA DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF ANY
                INFORMATION INCLUDED IN ANY TRIPS OR OTHER INFORMATION CREATED
                OR PROVIDED THROUGH THE SERVICE OR OBTAINED FROM THIRD PARTY
                HOTELS, AIRLINES OR OTHER TRAVEL SUPPLIERS. USERS ARE SOLELY
                RESPONSIBLE FOR ENSURING THE ACCURACY OF ALL INFORMATION
                PROVIDED TO THE SERVICE AND EXPA SHALL UNDERTAKE NO
                RESPONSIBILITY FOR DAMAGES CAUSED BY THE INCLUSION OF ERRONEOUS,
                INCOMPLETE OR OUTDATED INFORMATION IN SUCH TRIPS.
              </div>
              <div className="mt-5">
                THE HOTELS, AIRLINES, AND OTHER TRAVEL SUPPLIERS PROVIDING
                TRAVEL OR OTHER SERVICES TO YOU ARE INDEPENDENT CONTRACTORS AND
                NOT AGENTS OR EMPLOYEES OF EXPA OR ITS AFFILIATES.
                EXPA’S DISPLAY THROUGH THE SERVICE OF TRAVEL INFORMATION
                OF, OR ABILITY TO CONNECT ACCOUNTS WITH, SUCH THIRD PARTIES DOES
                NOT CONSTITUTE ANY SPONSORSHIP OR APPROVAL BY EXPA OF SUCH
                SUPPLIER OR ANY AFFILIATE OF SUCH SUPPLIER. YOUR INTERACTION
                WITH SUCH SUPPLIERS IS AT YOUR OWN RISK. EXPA AND ITS
                AFFILIATES WILL HAVE NO LIABILITY FOR THE ACTS OR OMISSIONS,
                REPRESENTATIONS OR NEGLIGENCE OF ANY SUCH SUPPLIERS OR FOR ANY
                PERSONAL INJURIES, DEATH, PROPERTY DAMAGE, OR OTHER DAMAGES OR
                EXPENSES RESULTING THEREFROM. EXPA AND ITS AFFILIATES HAVE
                NO LIABILITY AND WILL MAKE NO REFUND IN THE EVENT OF ANY DELAY,
                CANCELLATION, OVERBOOKING, STRIKE, FORCE MAJEURE, OR OTHER
                CAUSES BEYOND OUR DIRECT CONTROL.
              </div>
              <div className="mt-5">
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EXPA,
                ITS AFFILIATES, AND THEIR RESPECTIVE SERVICE PROVIDERS SHALL NOT
                BE LIABLE TO YOU OR ANY THIRD PARTIES FOR ANY INDIRECT, SPECIAL,
                CONSEQUENTIAL OR PUNITIVE DAMAGES RELATED TO THE SERVICES,
                INCLUDING ANY RELIANCE ON CONTENT OR INFORMATION IN THE
                SERVICES. REGARDLESS OF THE TYPE OF CLAIM OR THE NATURE OF THE
                CAUSE OF ACTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES.
              </div>
              <div className="mt-5">
                SOME JURISDICTIONS DO NOT ALLOW CERTAIN DISCLAIMERS SUCH AS THE
                EXCLUSION OF IMPLIED WARRANTIES OR THE LIMITATION OR EXCLUSION
                OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, IN WHICH
                CASE CERTAIN DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU.
                YOU MAY ALSO HAVE OTHER RIGHTS THAT MAY VARY BY JURISDICTION.
              </div>
            </TermsSection>

            <TermsSection title="9. Service modification and availability; Termination or suspension of access to the service">
              <div className="mt-5">
                Expa has the right to modify the Service at any time
                without notice, including adding or removing features or
                functionality. Not all features and functionality will be
                available in all markets, and the features and functionality of
                the App may be different than the version of the Service on the
                website. Expa has the right to terminate and/or suspend
                your ability to access the Service or any portion thereof, for
                any or no reason, without notice.
              </div>
            </TermsSection>

            <TermsSection title="10. Governing law; Venue">
              <div className="mt-5">
                This Agreement, including any disputes in court or arbitration,
                will be governed in all respects by the laws of the State of
                Ohio as they apply to agreements entered into and to be
                performed entirely within Ohio between Ohio residents, without
                regard to conflict of law provisions. Subject to the mandatory
                arbitration provisions below, any judicial proceeding to resolve
                claims relating to this Agreement will be brought in federal or
                state courts of Franklin County, Ohio, and you consent to venue
                and personal jurisdiction in the courts located within Franklin
                County, Ohio for this purpose. If you reside in a country with
                laws that give consumers the right to bring disputes in their
                local courts, this paragraph doesn’t affect those requirements.
              </div>
            </TermsSection>

            <TermsSection title="11. Additional provisions for United States users – Arbitration; Class action waiver">
              <div className="mt-5">
                <ol className="list-roman list-outside pl-5">
                  <li className="mt-4">
                    <p className="mt-2">
                      If you are a user located in the United States, all
                      disputes between you and Expa will be resolved by
                      BINDING ARBITRATION as described in more detail in this
                      section. You therefore GIVE UP YOUR RIGHT TO GO TO COURT
                      to assert or defend your rights. Except for intellectual
                      property rights or matters that you may bring in small
                      claims court (as described below), the term “dispute”
                      means any dispute, action, or other controversy between
                      you and Expa regarding the Services or this
                      Agreement, whether in contract, warranty, tort, or
                      pursuant to statute or regulation, or any other legal or
                      equitable basis and will be given the broadest possible
                      meaning allowable under law.
                    </p>
                    <p className="mt-2">
                      Your rights will be determined by a NEUTRAL ARBITRATOR and
                      NOT a judge or jury and your claims cannot be brought as a
                      class action. You are entitled to a fair hearing, but the
                      arbitration procedures are simpler and more limited than
                      they would be in court. Arbitrator decisions are as
                      enforceable as any court order and are subject to very
                      limited review by courts or judges. Arbitrators can award
                      most if not all of the same damages and relief that a
                      court can award. Any arbitration will take place on an
                      individual basis. Class arbitrations and class actions are
                      not permitted.
                    </p>
                  </li>
                  <li className="mt-4">
                    <p className="mt-2">
                      You and Expa agree that any dispute arising out of or
                      relating in any way to the Service or this Agreement shall
                      be determined by binding arbitration instead of in courts
                      of general jurisdiction, except as set forth below. You
                      and Expa agree that this arbitration agreement
                      extends to the determination of the scope or applicability
                      of this agreement to arbitrate. You and Expa are each
                      waiving the right to a trial by jury or to participate in
                      a class action. The Federal Arbitration Act governs the
                      interpretation and enforcement of this provision. This
                      arbitration provision shall survive termination of your
                      use of the Service. You and Expa each retain the
                      right to bring an individual action in small claims court
                      and the right to seek injunctive or other equitable relief
                      in a court of competent jurisdiction to prevent the actual
                      or threatened infringement, misappropriation or violation
                      of a party’s copyrights, trademarks, trade secrets,
                      patents or other intellectual property rights.
                    </p>
                  </li>
                  <li className="mt-4">
                    <p className="mt-2">
                      In the event of a dispute, you must first send to
                      Expa, by email, a Notice of Dispute (“Notice”). The
                      Notice to Expa should be sent via{" "}
                      <a
                        href="mailto:support@expa.com.au"
                        className="text-primary"
                      >
                        email
                      </a>
                      . The Notice must (a) describe the nature and basis of the
                      claim or dispute; and (b) set forth the specific relief
                      sought. Expa will send any Notice of Dispute to you
                      to your address or email address on file. You and
                      Expa will attempt to resolve any dispute through
                      informal negotiation within thirty (30) days of the date
                      of the Notice of Dispute. If no resolution is reached,
                      either party may then commence arbitration pursuant to the
                      rules identified below or file a claim in small claims
                      court.
                    </p>
                  </li>
                  <li className="mt-4">
                    <p className="mt-2">
                      Any arbitration will be governed by the Consumer
                      Arbitration Rules (“AAA Rules”) of the American
                      Arbitration Association (“AAA”) and will be administered
                      by the AAA. The AAA Rules are available online at{" "}
                      <a
                        href="https://www.adr.org"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary"
                      >
                        www.adr.org
                      </a>
                      , by calling the AAA at 1-800-778-7879. All issues are for
                      the arbitrator to decide, including issues relating to the
                      scope and enforceability of this arbitration agreement.
                    </p>
                  </li>
                  <li className="mt-4">
                    <p className="mt-2">
                      If your claim is for $10,000 or less, we agree that you
                      may choose whether the arbitration will be conducted
                      solely on the basis of documents submitted to the
                      arbitrator, through a telephonic hearing, or by an
                      in-person hearing as established by the AAA Rules. Any
                      arbitration hearings will take place in the county of your
                      primary residence, unless the parties agree otherwise. If
                      your claim exceeds $10,000, the right to a hearing will be
                      determined by the AAA Rules. Regardless of the manner in
                      which the arbitration is conducted, the arbitrator shall
                      issue a reasoned written decision sufficient to explain
                      the essential findings and conclusions on which the award
                      is based.
                    </p>
                  </li>
                  <li className="mt-4">
                    <p className="mt-2">
                      If your claim is for less than $10,000, after Expa
                      receives proper notice that you have commenced arbitration
                      pursuant to the AAA Rules, Expa will promptly
                      reimburse you for your payment of the filing fee.
                      Expa will pay all AAA administration and arbitrator
                      fees. If, however, the arbitrator finds in favor of
                      Expa as to the substance of your claim or the relief
                      sought, you will reimburse Expa for your portion of
                      the filing fee. If the arbitrator finds that either the
                      substance of your claim or the relief sought in the demand
                      is frivolous or brought for an improper purpose (as
                      measured by the standards set forth in Federal Rule of
                      Civil Procedure 11(b)), then the payment of all AAA
                      filing, administration, and arbitrator fees will be
                      governed by the AAA Rules. In such case, you agree to
                      reimburse Expa for all amounts previously provided by
                      Expa that are otherwise your obligation to pay under
                      the AAA Rules. In addition, if you initiate an arbitration
                      in which you seek more than $10,000 in damages, the
                      payment of these fees will be governed by the AAA rules.
                    </p>
                  </li>
                  <li className="mt-4">
                    <p className="mt-2">
                      Either party must commence an arbitration or small claims
                      court claim within one (1) year from when it could first
                      be filed. Otherwise, it is permanently barred.
                    </p>
                  </li>
                  <li className="mt-4">
                    <p className="mt-2">
                      The arbitrator may award declaratory or injunctive relief
                      only in favor of the party seeking relief and only to the
                      extent necessary to provide relief warranted by that
                      party’s claim. YOU AND EXPA AGREE THAT EACH MAY BRING
                      CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
                      CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                      PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further,
                      unless both you and Expa agree otherwise, the
                      arbitrator may not consolidate more than one person’s
                      claims with your claims, and may not otherwise preside
                      over any form of a representative or class proceeding. If
                      any portion of this arbitration agreement is found to be
                      unenforceable or unlawful for any reason, (a) the
                      unenforceable or unlawful provision shall be severed from
                      these terms; (b) severance of the unenforceable or
                      unlawful provision shall have no impact whatsoever on the
                      remainder of the arbitration agreement or the parties’
                      ability to compel arbitration of any remaining claims on
                      an individual basis pursuant to the arbitration agreement;
                      and (c) to the extent that any claims must therefore
                      proceed on a class, collective, consolidated, or
                      representative basis, such claims must be litigated in a
                      civil court of competent jurisdiction and not in
                      arbitration, and the parties agree that litigation of
                      those claims shall be stayed pending the outcome of any
                      individual claims in arbitration.
                    </p>
                  </li>
                  <li className="mt-4">
                    <p className="mt-2">
                      You may opt-out of this agreement to arbitrate within
                      thirty (30) days from the date that this Agreement is
                      effective (the “Opt-Out Deadline”). To opt-out you must
                      notify us via{" "}
                      <a
                        href="mailto:support@expa.com.au"
                        className="text-primary"
                      >
                        email
                      </a>
                      . Your notification must include (1) your name, (2) your
                      address, and (3) a clear statement that you do not agree
                      to the arbitration agreement. Your decision to opt-out
                      will have no adverse effect on your relationship with
                      Expa. Any opt-out request received after the Opt-Out
                      Deadline will not be valid and you must pursue your
                      dispute in arbitration or small claims court.
                    </p>
                  </li>
                  <li className="mt-4">
                    <p className="mt-2">
                      You may reject any change we make to this section (other
                      than administrative changes, such as changes to an address
                      or corrections) by sending us notice in the manner set
                      forth in the paragraph above. If you do, the most recent
                      version of the arbitration agreement before the agreement
                      will apply to you.
                    </p>
                  </li>
                </ol>
              </div>
            </TermsSection>

            <TermsSection title="12. General provisions">
              <div className="mt-5">
                If any provision of this Agreement is held to be invalid or
                unenforceable, such provision shall be struck and the remaining
                provisions shall be enforced. In our sole discretion, we may
                assign this Agreement. Headings are for reference purposes only
                and do not limit the scope or extent of such section. Our
                failure to act with respect to a breach by you or others does
                not waive our right to act with respect to subsequent or similar
                breaches. We do not guarantee we will take action against all
                breaches of this Agreement.
              </div>
              <div className="mt-5">
                This Agreement is written in the English language. If any
                translated versions of this Agreement conflict with the English
                language version, the English language version of this Agreement
                shall control.
              </div>
            </TermsSection>
          </div>
        </div>
      </section>
    </MarketingLayout>
  );
};

const TermsSection: FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => (
  <div className="bg-white rounded border border-gray-400 leading-relaxed mb-8">
    <div className="flex-auto p-6 lg:p-10">
      <div className="text-primary font-bold mb-5">{title}</div>
      <div className="text-sm">{children}</div>
    </div>
  </div>
);
