import React, { FC, ReactNode, PropsWithChildren } from "react";

import { Banner } from "./banner";
import { Footer } from "./footer";
import { Header } from "./header";
import { QuickSignup } from "./quick-signup";

import LogoPatternImage from "../../../assets/images/logo-pattern.png";

type MarketingLayoutType = {
  title: string;
  subtitle: string | ReactNode;
  isTallBanner?: boolean;
};

const styleBackground = {
  backgroundImage: `url(${LogoPatternImage})`,
  backgroundRepeat: "repeat",
};

export const MarketingLayout: FC<PropsWithChildren<MarketingLayoutType>> = ({
  title,
  subtitle,
  isTallBanner = false,
  children,
}) => {
  return (
    <div className="font-body tracking-wide text-gray-700">
      <Header />
      <Banner title={title} subtitle={subtitle} isTall={isTallBanner} />
      {children}
      <section className="relative block py-20 bg-dark" style={styleBackground}>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center">
            <div className="w-full max-w-md px-4">
              <div className="bg-white rounded shadow mb-6">
                <QuickSignup />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
