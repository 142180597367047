import React, { useEffect, useRef, useState } from "react";

import { AppLayout } from "../app-layout";
import { Results } from "./result.page";
import Loader from "./loader";
import { useSelector } from "react-redux";
import { directoryState } from "../../../redux/slices/directorySlice";

export const RepaymentResultsContext = React.createContext({});

export const CalculatorPage = () => {
  const [repaymentResultsData, setRepaymentResultsData] = useState({});

  const loadingState = useSelector(directoryState);

  const [currentWidth, setCurrentWidth] = useState(0);

  const loadingRef = useRef(null);

  useEffect(()=>{
    if(loadingRef?.current){
      //@ts-ignore
      setCurrentWidth(loadingRef?.current?.clientWidth)
    }
  }, [loadingRef])

  return (
    <RepaymentResultsContext.Provider
      value={{
        repaymentResultsData,
        setRepaymentResultsData,
      }}
    >
      <AppLayout title="Calculator">
        <div className="flex flex-row">
          <div className="container" ref={loadingRef}>
            {loadingState.isLoading && <Loader currentWidth={currentWidth}/>}
            <Results />
          </div>
        </div>
      </AppLayout>
    </RepaymentResultsContext.Provider>
  );
};
