import React, { FC, PropsWithChildren } from "react";

import BackpackArt from "../../assets/illustrations/backpack.svg";
import CakeArt from "../../assets/illustrations/cake.svg";
import LuggageArt from "../../assets/illustrations/luggage.svg";
import SuitcaseArt from "../../assets/illustrations/suitcase.svg";
import TicketArt from "../../assets/illustrations/ticket.svg";
import TravelArt from "../../assets/illustrations/travel.svg";
import NotFoundArt from "../../assets/illustrations/404.svg";

type IllustrationType = { icon: string };

export const Illustration: FC<PropsWithChildren<IllustrationType>> = ({
  icon,
}) => {
  const iconMap: Record<string, string> = {
    backpack: BackpackArt,
    cake: CakeArt,
    luggage: LuggageArt,
    suitcase: SuitcaseArt,
    ticket: TicketArt,
    travel: TravelArt,
    "404": NotFoundArt,
  };

  return (
    <div>
      <img src={iconMap[icon]} alt="" className="w-full pointer-events-none" />
    </div>
  );
};
