import { useEffect, useState, useCallback } from "react";
import DirectoryCustomDropdown from "./customDropdown/customDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAddUpliftData,
  clearUpliftData,
  directoryState as ds,
  updateBracketData,
  updateProductsData,
  updateUpliftData,
} from "../../../../redux/slices/directorySlice";
import AddBracketModal from "../../financier/modal/addBracketModal";
import { useMediaQuery } from "react-responsive";
import DeleteModal from "../../financier/modal/deleteModal";
import { deleteRateBracket } from "../../../../services/rate-brackets.service";
import { customToast } from "../../../shared-components/custom-toast";

interface Company {
  id: number;
  abn: string;
  created_at: string;
  type: string;
  name: string;
  updated_at: string;
}

export const BracketFilter = () => {
  const [companyData, setCompanyData] = useState<Company>();
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const directoryState = useSelector(ds);
  const dispatch = useDispatch();
  const [reloadData, setReloadData] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hovered, setHovered] = useState(false);
  useEffect(() => {
    dispatch(clearUpliftData());
    dispatch(clearAddUpliftData());
    dispatch(updateBracketData(null));
  }, [reloadData]);
  useEffect(() => {
    setHovered(directoryState.bracketData ? false : true);
  }, [directoryState]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });
  const isLoading = () => {
    return (
      directoryState.orgLoading ||
      directoryState.productLoading ||
      directoryState.bracketLoading
    );
  };
  const handleDelete = () => {
    deleteRateBracket(directoryState.bracketData.id)
      .then(successCallback)
      .catch(errorCallback);
  };
  const successCallback = useCallback((res: any) => {
    customToast({ message: res.data.msg, type: "success" });
    setShowDeleteModal(false);
    dispatch(clearUpliftData());
    dispatch(updateProductsData(null));
    dispatch(updateBracketData(null));
    dispatch(updateUpliftData(null));
    setReloadData(!reloadData);
  }, []);
  const errorCallback = useCallback((error: any) => {
    customToast({
      message: `${error.response.data.error || "Something went wrong"}`,
      type: "error",
    });
    setShowDeleteModal(false);
    setShowModal(true);
  }, []);
  return (
    <div
      className={`${
        hovered ? "bg-[#3e2d8d] text-white" : "bg-[#fbca5d] text-black"
      } ${isLoading() && "opacity-30"} rounded-lg p-[2%] mt-[2%]`}
    >
      <div
        className={`flex ${
          isSmallScreen ? "flex-col" : "flex-row"
        } items-center`}
      >
        {isSmallScreen ? (
          <div className="flex flex-row w-1/2 items-center justify-between">
            <div className=" text-[24px] font-[700] pr-[3.5%]">Bracket</div>
            <AddBracketModal
              type={"add"}
              hovered={hovered}
              setReloadData={setReloadData}
              reloadData={reloadData}
              isLoading={isLoading()}
              showModal={showAddModal}
              setShowModal={setShowAddModal}
              setShowDeleteModal={setShowDeleteModal}
            />
          </div>
        ) : (
          <>
            <div className=" text-[24px] font-[700] pr-[3.5%]">Bracket</div>
            <AddBracketModal
              type={"add"}
              hovered={hovered}
              setReloadData={setReloadData}
              reloadData={reloadData}
              isLoading={isLoading()}
              showModal={showAddModal}
              setShowModal={setShowAddModal}
              setShowDeleteModal={setShowDeleteModal}
            />
          </>
        )}
        <DirectoryCustomDropdown
          setCompanyData={setCompanyData}
          componentName="BracketFilter"
          reloadData={reloadData}
          hovered={hovered}
        />
        {directoryState.bracketData ? (
          <>
            <div
              className={`flex ${
                isSmallScreen ? "flex-row w-full justify-between" : "flex-col"
              } flex-1  px-[2%]`}
            >
              <div>Base Rate</div>
              <div className="font-[700]">
                {directoryState.bracketData.base_rate} %
              </div>
            </div>
            <AddBracketModal
              type={"edit"}
              hovered={hovered}
              setReloadData={setReloadData}
              reloadData={reloadData}
              isLoading={isLoading()}
              showModal={showModal}
              setShowModal={setShowModal}
              setShowDeleteModal={setShowDeleteModal}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <DeleteModal
        setShowdeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
        setEditModal={setShowModal}
        handleDelete={handleDelete}
        title="Base Rate"
        name={directoryState?.bracketData?.bracket_name ?? ""}
      />
    </div>
  );
};
