import axios from "axios";
import {
  RATE_BRACKETS_API_URL,
  RATE_BRACKETS_PRODUCT_API_URL,
} from "../api.routes";
import { authHeader } from "../auth.header";

export async function getRateBracketsOfProduct(product_id: number) {
  return await axios.get(RATE_BRACKETS_PRODUCT_API_URL + product_id, {
    headers: authHeader(),
  });
}

export async function getRateBracket(rate_bracket_id: number) {
  return await axios.get(RATE_BRACKETS_API_URL + rate_bracket_id, {
    headers: authHeader(),
  });
}

export async function getAllRateBrackets() {
  return await axios.get(RATE_BRACKETS_API_URL, {
    headers: authHeader(),
  });
}

export async function addRateBrackets(params: any) {
  return await axios.post(RATE_BRACKETS_API_URL, params, {
    headers: authHeader(),
  });
}

export async function updateRateBracketData(params: any) {
  return await axios.patch(
    RATE_BRACKETS_API_URL + params.bracket_id + "/",
    params,
    {
      headers: authHeader(),
    }
  );
}

export async function deleteRateBracket(rate_bracket_id:number){
  return await axios.delete(RATE_BRACKETS_API_URL + rate_bracket_id + "/", {
    headers: authHeader(),
  });
}