import React, { useEffect, useState, useCallback } from "react";
import DirectoryCustomDropdown from "./customDropdown/customDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAddUpliftData,
  clearUpliftData,
  directoryState as ds,
  updateBracketData,
  updateProductsData,
  updateUpliftData,
  updateDirectoryComapnyId,
  updateToggleCompany
} from "../../../../redux/slices/directorySlice";
import { HorizontalDivider } from "../../form-components/divider";
import ModalComponent from "../../financier/modal/addProductModalComponent";
import { HorizontalLightBlueDivider } from "../../form-components/divider";
import { useMediaQuery } from "react-responsive";
import DeleteModal from "../../financier/modal/deleteModal";
import { deleteProduct } from "../../../../services/products.service";
import { customToast } from "../../../shared-components/custom-toast";

interface Company {
  id: number;
  abn: string;
  created_at: string;
  type: string;
  name: string;
  updated_at: string;
}

export const ProductsFilter = () => {
  const [companyData, setCompanyData] = useState<Company>();
  const directoryState = useSelector(ds);
  const dispatch = useDispatch();
  const [reloadData, setReloadData] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    dispatch(updateProductsData(null));
    dispatch(updateBracketData(null));
    dispatch(clearUpliftData());
    dispatch(clearAddUpliftData());
  }, [reloadData]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });
  useEffect(() => {
    setHovered(directoryState.productsData ? false : true);
  }, [directoryState]);

  const handleDelete = () => {
    deleteProduct(directoryState.productsData.id)
      .then(successCallback)
      .catch(errorCallback);
  };
  const successCallback = useCallback((res: any) => {
    customToast({ message: res.data.msg, type: "success" });
    setShowDeleteModal(false);
    dispatch(clearUpliftData());
    dispatch(updateToggleCompany(!directoryState.toggleCompany));
    dispatch(updateProductsData(null));
    dispatch(updateBracketData(null));
    dispatch(updateUpliftData(null));
    setReloadData(!reloadData);
  }, []);
  const errorCallback = useCallback((error: any) => {
    customToast({
      message: `${error.response.data.error || "Something went wrong"}`,
      type: "error",
    });
    setShowDeleteModal(false);
    setShowModal(true);
  }, []);

  const isLoading = () => {
    return directoryState.orgLoading || directoryState.productLoading;
  };
  return (
    <div
      className={`${
        hovered ? "bg-[#3e2d8d] text-white" : "bg-[#fbca5d] text-black"
      } ${isLoading() && "opacity-40"} rounded-lg p-[2%] mt-[3%]`}
    >
      <div
        className={`flex ${
          isSmallScreen ? "flex-col" : "flex-row"
        } items-center ${directoryState.productsData && "mb-[1%]"}`}
      >
        {isSmallScreen ? (
          <div className="flex flex-row items-center w-1/2 justify-between">
            <div className=" text-[24px] font-[700] pr-[3.7%] font-grotesk">
              Product
            </div>
            <ModalComponent
              componentType="add"
              setReloadData={setReloadData}
              reloadData={reloadData}
              hovered={hovered}
              isLoading={isLoading()}
              showModal={showAddModal}
              setShowModal={setShowAddModal}
              setShowDeleteModal={setShowDeleteModal}
            />
          </div>
        ) : (
          <>
            <div className=" text-[24px] font-[700] pr-[3.7%] font-grotesk">
              Product
            </div>
            <ModalComponent
              componentType="add"
              setReloadData={setReloadData}
              reloadData={reloadData}
              hovered={hovered}
              isLoading={isLoading()}
              showModal={showAddModal}
              setShowModal={setShowAddModal}
              setShowDeleteModal={setShowDeleteModal}
            />
          </>
        )}
        <DirectoryCustomDropdown
          setCompanyData={setCompanyData}
          componentName="ProductsFilter"
          reloadData={reloadData}
          hovered={hovered}
        />
        {directoryState.productsData ? (
          <>
            <div
              className={`flex ${
                isSmallScreen ? "flex-row w-full justify-between" : "flex-col"
              } px-[2%]`}
            >
              <div className={`${hovered && " opacity-60"}`}>Amount Range</div>
              <div className="font-[700]">
                <span className="font-normal opacity-80">$ </span>
                {directoryState.productsData.min_amount} -{" "}
                <span className="font-normal opacity-80">$ </span>
                {directoryState.productsData.max_amount}
              </div>
            </div>
            <div
              className={`flex ${
                isSmallScreen ? "flex-row w-full justify-between" : "flex-col"
              } px-[2%] flex-1`}
            >
              <div className={`${hovered && " opacity-60"}`}>Term Range</div>
              <div className=" font-[700]">
                {directoryState.productsData.min_range} -{" "}
                {directoryState.productsData.max_range} months
              </div>
            </div>
            <ModalComponent
              componentType="edit"
              setReloadData={setReloadData}
              reloadData={reloadData}
              hovered={hovered}
              showModal={showModal}
              setShowModal={setShowModal}
              setShowDeleteModal={setShowDeleteModal}
              isLoading={isLoading()}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {directoryState.productsData && (
        <>
          {!isSmallScreen && (
            <>
              {hovered ? (
                <HorizontalLightBlueDivider />
              ) : (
                <HorizontalDivider marginY={5} />
              )}
            </>
          )}
          <div
            className={`flex ${
              isSmallScreen ? "flex-col" : "flex-row"
            } w-full text-base mt-[1%]`}
          >
            <div
              className={`flex flex-col ${!isSmallScreen && "w-1/3 border-r"} ${
                hovered ? "border-[#6457a4]" : "border-white"
              } pr-[3%] mr-[3%]`}
            >
              <div className="flex justify-between items-center flex-row my-2">
                <div className={`${hovered && " opacity-70"}`}>Monthly fee</div>
                <div className="font-[700]">
                  <span className="font-normal opacity-80">$ </span>
                  {directoryState.productsData.monthly_fee || "0"}
                </div>
              </div>
              <div className="flex justify-between items-center flex-row my-1">
                <div className={`${hovered && " opacity-70"}`}>
                  Documentation fee
                </div>
                <div className="font-[700]">
                  <span className="font-normal opacity-80">$ </span>
                  {directoryState.productsData.documentation_fee || "0"}
                </div>
              </div>
            </div>
            <div
              className={`flex flex-col ${!isSmallScreen && "w-1/3 border-r"} ${
                hovered ? "border-[#6457a4]" : "border-white"
              } pr-[3%] mr-[3%]`}
            >
              <div className="flex justify-between items-center flex-row my-2">
                <div className={`${hovered && " opacity-70"}`}>
                  Maximum Brokerage Limit
                </div>
                <div className="font-[700]">
                  {directoryState.productsData.maximum_brokerage_limit || "0"} %
                </div>
              </div>
              <div className="flex justify-between items-center flex-row my-1">
                <div className={`${hovered && " opacity-70"}`}>
                  Brokerage cap
                </div>
                <div className="font-[700]">
                  {directoryState.productsData.brokerage_cap || "0"} %
                </div>
              </div>
            </div>
            <div
              className={`flex flex-col ${
                !isSmallScreen ? "w-1/3" : "mr-[5%]"
              }`}
            >
              <div className="flex justify-between items-center flex-row my-2">
                <div className={`${hovered && " opacity-70"}`}>
                  Compounding Frequency
                </div>
                <div className="font-[700]">
                  {directoryState.productsData.compounding_frequency === "m"
                    ? "Monthly"
                    : directoryState.productsData.compounding_frequency === "q"
                    ? "Quaterly"
                    : directoryState.productsData.compounding_frequency === "y"
                    ? "Yearly"
                    : "Weekly"}
                </div>
              </div>
              <div className="flex justify-between items-center flex-row my-1">
                <div className={`${hovered && " opacity-70"}`}>
                  Brokerage Loading Treatment
                </div>
                <div className="font-[700]">
                  <span className="font-normal opacity-80">$ </span>
                  {directoryState.productsData.brokerage_loading_treatment ||
                    "0"}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <DeleteModal
        setEditModal={setShowModal}
        setShowdeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
        title="Product"
        name={directoryState?.productsData?.product_name ?? ""}
        handleDelete={handleDelete}
      />
    </div>
  );
};
