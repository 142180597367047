import React from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

export const QuickSignup = () => {
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const handleQuickSignup = (params: any) => {
    navigate(`/signup?email=${params.email}`, { replace: true });
  };

  return (
    <div className="flex-auto p-6 lg:p-10">
      <h4 className="font-display text-lg text-center mb-6">
        Start tracking today!
      </h4>
      <form onSubmit={handleSubmit(handleQuickSignup)}>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Email"
            {...register("email")}
          />
        </fieldset>
        <input
          className="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer"
          type="submit"
          value="Signup for free"
        />
      </form>
    </div>
  );
};
