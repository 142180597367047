import React, { FC } from "react";

const styleHeight3 = { height: "3px" };

type ProgressBarType = {
  isLoading: boolean;
  isBottom?: boolean;
  color?: string;
};
export const ProgressBar: FC<ProgressBarType> = ({
  isLoading,
  isBottom = false,
  color = "green",
}) => (
  <div
    className={`w-full absolute overflow-hidden bg-opacity-25 transition-all ${
      isBottom ? "bottom-0" : "top-0"
    } ${isLoading ? "opacity-100" : "opacity-0"} bg-${color}`}
    style={styleHeight3}
  >
    <div className={`w-full h-full progress-animation bg-${color}`}></div>
  </div>
);
