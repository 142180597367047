import React from "react";

const EmptyFilters = () => {
  return (
    <div className="text-[#b3b3b3] font-grotesk text-4xl p-20 font-[500] bg-white justify-center items-center w-full text-center mr-[5%] rounded-lg">
      Select fields above to see calculated results...
    </div>
  );
};

export default EmptyFilters;
