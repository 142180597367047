import { default as axios } from "axios";
import * as ls from "local-storage";
import { authHeader } from "../auth.header";
import { getAndDecodeAccessToken } from "./accessToken.service";

import { USERS_API_URL } from "../api.routes";

export async function getProfile(id: string) {
  return await axios.get(USERS_API_URL + id, {
    headers: authHeader(),
  });
}

export function getUserDetailsViaToken() {
  const decodedData = getAndDecodeAccessToken();
  if (decodedData) {
    return axios(USERS_API_URL + decodedData["user_id"], {
      headers: authHeader(),
    })
      .then((res) => {
        ls.set("userData", JSON.stringify(res.data));
        return res.data;
      })
      .catch((err) => null);
  }
  return null;
}

export async function updateProfile(id: string, params: object) {
  return await axios.patch(USERS_API_URL + id + "/", params, {
    headers: authHeader(),
  });
}
