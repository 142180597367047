import React, {FC, useCallback, useState} from 'react';
import {AuthLayout} from "./login.layout";
import {useForm} from "react-hook-form";
import resetPasswordService, {ResetPasswordPayload} from "../../services/reset-password.service";
import getPasswordSchema from "../../helpers/getPasswordSchema";
import {customToast} from "../shared-components/custom-toast";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {userStatus} from "../../redux/slices/userDataSlice";

interface ResetPasswordPageProps {
}

interface ResetPasswordFormData extends Omit<ResetPasswordPayload, 'uid' | 'token'> {
}

const ResetPasswordPage: FC<ResetPasswordPageProps> = () => {

  const navigate = useNavigate();
  const {state} = useLocation();
  const from = state?.from || "/calculator";
  const {uid, token} = useParams<{ uid: string, token: string }>();

  const userIsLoggedIn = useSelector(userStatus);

  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState("");

  const schema = getPasswordSchema();

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    watch
  } = useForm<ResetPasswordFormData>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onBlur'
  });

  const successCallback = useCallback(
    () => {
      customToast({
        message: `Password changed successfully`,
        type: "success",
      });
      setIsLoading(false);
      navigate('/login', {replace: true});
    },
    [navigate]
  );

  const errorCallback = useCallback((error: any) => {
    setIsLoading(false);
    setResetPasswordError(
      error.response.data.message || "Something went wrong"
    );
  }, []);

  const onSubmit = (data: ResetPasswordFormData) => {
    setIsLoading(true);
    setResetPasswordError('');

    const result = schema.validate(data.password, {list: true});
    if (Object.keys(result).length !== 0) {
      setResetPasswordError(
        "Password must have at least 1 Uppercase, 1 Smaller case, 1 Special, 1 Digit character and No Space is allowed"
      );
      setIsLoading(false);
      return;
    }

    const payload: ResetPasswordPayload = {
      ...data,
      uid: String(uid),
      token: String(token),
    };
    resetPasswordService(payload)
      .then(successCallback)
      .catch(errorCallback);
  }

  if (userIsLoggedIn) return <Navigate to={from} replace={true}/>;

  return (
    <AuthLayout title='Reset Your Password' isLoading={isLoading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Password"
            {...register("password", {
              required: "This input is required.",
              maxLength: {
                value: 68,
                message: "This input can't exceed 254 characters",
              },
              minLength: {
                value: 6,
                message: "This input requires at least 6 characters",
              },
            })}
          />
          {errors.password?.message ? (
            <div className="px-3 text-red-400">{errors.password?.message}</div>
          ) : (
            <></>
          )}
        </fieldset>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="confirm_password">
            Confirm Password
          </label>
          <input
            type="password"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Confirm Password"
            {...register("confirmPassword", {
              required: "This input is required.",
              validate: (val: string) => {
                if (watch("password") !== val) {
                  return "Passwords does not match";
                }
              },
            })}
          />
          {errors.confirmPassword?.message ? (
            <div className="px-3 text-red-400">
              {errors.confirmPassword?.message}
            </div>
          ) : (
            <></>
          )}
        </fieldset>
        <fieldset className="mb-6">
          <input
            className="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
            disabled={!isValid || isLoading}
            type="submit"
            value={isLoading ? "Please wait..." : "Reset Password"}
          />
          <div
            style={{wordBreak: "keep-all"}}
            className={`bg-red-100 border border-red-400 text-red-600 text-center text-sm px-4 py-3 rounded mt-4  ${
              resetPasswordError ? "" : "hidden"
            }`}
          >
            {resetPasswordError}
          </div>
        </fieldset>
      </form>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
