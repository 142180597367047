import { FC, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  directoryState as ds,
  updateAddUpliftData,
  updateEditUpliftData,
} from "../../../../redux/slices/directorySlice";

interface Props {
  name: string;
  type: string;
}

const YesNoSwitch: FC<Props> = ({ name, type }) => {
  const directoryState = useSelector(ds);
  const dispatch = useDispatch();
  const [currentState, setCurrentState] = useState(
    (type !== "add") &&
    (directoryState?.editUpliftData[name]) ? "yes" : "no"
  );
  const handleYes = () => {
    setCurrentState("yes");
    if (name === "property_ownership_status") {
      if (type === "add") {
        dispatch(updateAddUpliftData({ property_ownership_status: true }));
      } else {
        dispatch(updateEditUpliftData({ property_ownership_status: true }));
      }
    }
    if (name === "sale_hire_back") {
      if (type === "add") {
        dispatch(updateAddUpliftData({ sale_hire_back: true }));
      } else {
        dispatch(updateEditUpliftData({ sale_hire_back: true }));
      }
    }
    if (name === "private_sale") {
      if (type === "add") {
        dispatch(updateAddUpliftData({ private_sale: true }));
      } else {
        dispatch(updateEditUpliftData({ private_sale: true }));
      }
    }
  };
  const handleNo = () => {
    setCurrentState("no");
    if (name === "property_ownership_status") {
      if (type === "add") {
        dispatch(updateAddUpliftData({ property_ownership_status: false }));
      } else {
        dispatch(updateEditUpliftData({ property_ownership_status: false }));
      }
    }
    if (name === "sale_hire_back") {
      if (type === "add") {
        dispatch(updateAddUpliftData({ sale_hire_back: false }));
      } else {
        dispatch(updateEditUpliftData({ sale_hire_back: false }));
      }
    }
    if (name === "private_sale") {
      if (type === "add") {
        dispatch(updateAddUpliftData({ private_sale: false }));
      } else {
        dispatch(updateEditUpliftData({ private_sale: false }));
      }
    }
  };
  return (
    <>
      <div className={`h-full items-center pr-2 w-full`}>
        <div className=" flex flex-row border-2 border-[#e5e5e5] rounded-3xl p-1 items-center">
          <div
            className={`text-black ${
              currentState === "yes" && "bg-[#e5e5e5] font-bold"
            } rounded-3xl p-1 cursor-default pl-4 pr-4 w-1/2 text-center`}
            onClick={() => {
              handleYes();
            }}
          >
            Yes
          </div>
          <div
            className={`text-black ${
              currentState === "no" && "bg-[#e5e5e5] font-bold"
            } rounded-3xl p-1 cursor-default pl-4 pr-4 w-1/2 text-center`}
            onClick={() => {
              handleNo();
            }}
          >
            No
          </div>
        </div>
      </div>
    </>
  );
};

export default YesNoSwitch;
