import React, {useCallback, useState} from "react";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";

import {useForm} from "react-hook-form";
import {AuthLayout} from "./login.layout";
import {customToast} from "../shared-components/custom-toast";
import forgotPasswordService, {ForgotPasswordPayload} from "../../services/forgot-password.service";
import {useSelector} from "react-redux";
import {userStatus} from "../../redux/slices/userDataSlice";

export const ForgotPasswordPage = () => {

  const navigate = useNavigate();
  const {state} = useLocation();
  const from = state?.from || "/calculator";

  const userIsLoggedIn = useSelector(userStatus);

  const [isLoading, setIsLoading] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState("");

  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
    getValues,
  } = useForm<ForgotPasswordPayload>({
    defaultValues: {
      email: "",
    },
  });
  const validateForm = () => {
    return watch("email").length;
  };

  const successCallback = useCallback(() => {
    const {email} = getValues();
    setIsLoading(false);
    customToast({
      message: `${email} wants to Reset Password. Please check you mail box`,
      type: "success",
    });
  }, [getValues]);

  const errorCallback = useCallback((error: any) => {
    setIsLoading(false);
    setForgotPasswordError(error.response.data.detail || "Something went wrong");
  }, []);

  const onReset = (credentials: ForgotPasswordPayload) => {
    setIsLoading(true);
    setForgotPasswordError("");
    forgotPasswordService({email: credentials.email})
      .then(successCallback)
      .catch(errorCallback);
  };

  if (userIsLoggedIn) return <Navigate to={from} replace={true}/>;

  return (
    <AuthLayout title="Forgot your password?" isLoading={isLoading}>
      <form onSubmit={handleSubmit(onReset)}>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Email"
            {...register("email", {
              required: "Email is required",
              maxLength: {
                value: 254,
                message: "This input can't exceed 254 characters",
              },
            })}
          />
          {errors.email?.message && (
            <div className="px-3 text-red-400">{errors.email.message}</div>
          )}
        </fieldset>
        <fieldset className="mb-6">
          <input
            className="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
            disabled={!validateForm() || isLoading}
            type="submit"
            value={isLoading ? "Please wait..." : "Reset password"}
          />
        </fieldset>
        <div
          style={{wordBreak: "break-all"}}
          className={`bg-red-100 border border-red-400 text-red-600 text-center text-sm px-4 py-3 rounded mt-4 ${
            forgotPasswordError ? "" : "hidden"
          }`}
        >
          {forgotPasswordError}
        </div>
      </form>
      <div className="text-center">
        <Link to="/login" className="text-primary">
          Back to login
        </Link>
      </div>
    </AuthLayout>
  );
};
