import React, { useState, useCallback } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as ls from "local-storage";

import { AuthLayout } from "./login.layout";
import { customToast } from "../shared-components/custom-toast";

import { login as loginAPI } from "../../services/login.service";
import { login, userStatus } from "../../redux/slices/userDataSlice";
import { getUserDetailsViaToken } from "../../services/profile.service";

export const LoginPage = () => {
  const userIsLoggedIn = useSelector(userStatus);
  const dispatch = useDispatch();
  const location = useLocation();
  const from = location.state?.from || "/calculator";

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const validateForm = () => {
    return watch("email").length && watch("password").length;
  };

  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const successCallback = useCallback(
    (res: any) => {
      setIsLoading(false);
      ls.set("accessToken", res.data.tokens.access);
      getUserDetailsViaToken()?.then(() =>
        dispatch(login({ isLoggedIn: true }))
      ); // Set User Data in Local Storage
      customToast({
        message: `Successfully logged in as ${res.data.email}`,
        type: "success",
      });
    },
    [dispatch]
  );

  const errorCallback = useCallback((error: any) => {
    setIsLoading(false);
    setLoginError(error.response.data.detail || "Something went wrong");
  }, []);

  const onLogin = (credentials: any) => {
    setIsLoading(true);
    setLoginError("");
    loginAPI(credentials).then(successCallback).catch(errorCallback);
  };

  if (userIsLoggedIn) return <Navigate to={from} replace={true} />;

  return (
    <AuthLayout title="Welcome back!" isLoading={isLoading}>
      <form onSubmit={handleSubmit(onLogin)}>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Email"
            {...register("email", {
              required: "This input is required.",
              maxLength: {
                value: 254,
                message: "This input can't exceed 254 characters",
              },
            })}
          />
          {errors.email?.message ? (
            <div className="px-3 text-red-400">{errors.email?.message}</div>
          ) : (
            <></>
          )}
        </fieldset>
        <fieldset className="mb-3">
          <label className="block mb-1" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Password"
            {...register("password", {
              required: "This input is required.",
              maxLength: {
                value: 68,
                message: "This input can't exceed 254 characters",
              },
              minLength: {
                value: 6,
                message: "This input requires at least 6 characters",
              },
            })}
          />
          {errors.password?.message ? (
            <div className="px-3 text-red-400">{errors.password?.message}</div>
          ) : (
            <></>
          )}
        </fieldset>
        <div className="text-right text-sm mb-6">
          <Link to="/forgot-password" className="text-primary">
            Forgot password?
          </Link>
        </div>
        <fieldset className="mb-6">
          <input
            className="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
            disabled={!validateForm() || isLoading}
            type="submit"
            value={isLoading ? "Please wait..." : "Login"}
          />
          <div
            style={{ wordBreak: "break-all" }}
            className={`bg-red-100 border border-red-400 text-red-600 text-center text-sm px-4 py-3 rounded mt-4 ${
              loginError ? "" : "hidden"
            }`}
          >
            {loginError}
          </div>
        </fieldset>
      </form>
      <div className="text-center">
        New to Finput?{" "}
        <Link to="/signup" className="text-primary">
          Signup for free
        </Link>
      </div>
    </AuthLayout>
  );
};
