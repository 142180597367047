import React, { PropsWithChildren, FC, useState } from "react";
import { useSelector } from "react-redux";
import { companyProductSortState } from "../../../../redux/slices/companyProductSortingSlice";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as UnfilledTick } from "../../../../icons/empty_input.svg";
import { ReactComponent as FilledTick } from "../../../../icons/filled_input.svg";
import { ReactComponent as CancelBaseRate } from "../../../../icons/cancel_base_rate.svg";
import { ReactComponent as RateSavedIcon } from "../../../../icons/rate_saved_icon.svg";
import { ReactComponent as RateSavedIconHovered } from "../../../../icons/rate_saved_hovered.svg";

type RateRowType = {
  isGrid: boolean;
  title: any;
  base_range: string;
  effective_range: string;
  repayment_range: string;
  company_id: number;
  company_name: string;
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const RateRow: FC<PropsWithChildren<RateRowType>> = ({
  isGrid,
  title,
  base_range,
  effective_range,
  repayment_range,
  company_name
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [rate, setRate] = useState("");
  const [rateUnsaved, setRateUnsaved] = useState("");
  const [saved, setSaved] = useState(false);

  const companyProductSortingState = useSelector(companyProductSortState);

  const toggle = () => {
    setIsEditing(!isEditing);
    setRateUnsaved(rate);
  };

  const saveChanges = () => {
    setSaved(true);
    setIsEditing(false);
    setRate(rateUnsaved);
    setRateUnsaved("");
  };
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return isGrid ? (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`pt-[1.5%] pb-[1.5%] ${
        companyProductSortingState.label === "Product"
          ? "grid-cols-12 gap-3 ml-[1%]"
          : "grid-cols-8 gap-3 ml-[4%]"
      } ${
        isMobile ? "flex flex-col" : "grid justify-items-end"
      } tracking-wider py-2 items-center border-0 rounded-md hover:bg-[#3e2d8d] hover:text-white`}
    >
      {companyProductSortingState.label === "Product" && (
        <>
          <div
            className={`${
              isHovered ? "text-white" : "text-[#3e2d8d]"
            } font-bold w-3/4`}
          >
            {capitalizeFirstLetter(company_name)}
          </div>
          <div className="bg-[#3e2d8d] h-1 w-5/6"></div>
        </>
      )}
      {/* <div
        className={`${
          isHovered
            ? "text-white border-[#514298]"
            : "text-black border-[#e6e6e6]"
        } ${
          companyProductSortingState.label === "Product"
            ? "ml-[55%]"
            : "ml-[15%]"
        } text-sm font-bold justify-self-start p-1 border rounded-md flex items-center`}
      >
        {capitalizeFirstLetter(title)}
      </div>
      <div>.</div> */}
      <div className="flex flex-row col-span-2 items-center">
        <div
          className={`${isHovered ? "text-white" : "text-[black]"} text-base ${
            isHovered && !isEditing && !saved && "hover:bg-[#514298]"
          } ${
            saved &&
            rate.length > 0 &&
            (isHovered && !isEditing
              ? " font-[700] decoration-dashed decoration-white underline"
              : "font-[700] decoration-dashed decoration-black underline")
          } p-1 hover:underline justify-center decoration-dashed decoration-[#fbca5d] underline-offset-4 rounded cursor-pointer`}
          onClick={() => {
            !isEditing && toggle();
          }}
        >
          {isEditing ? (
            <div className="flex flex-row items-center">
              <div
                className={`border-2 bg-white border-yellow ${
                  !isEditing && "hover:bg-[#514298]"
                } rounded p-1 flex flex-row`}
              >
                <div className="text-black mx-2">%</div>
                <input
                  className="bg-white border-0 p-0 w-20 text-black font-bold"
                  placeholder="Enter"
                  type="number"
                  onChange={(e) => setRate(e.target.value)}
                  value={rate}
                  autoFocus={true}
                />
              </div>
              {rate.length === 0 ? (
                <div className="ml-1 cursor-default">
                  <UnfilledTick />
                </div>
              ) : (
                <div onClick={() => saveChanges()} className="ml-1">
                  <FilledTick />
                </div>
              )}
              <div onClick={() => toggle()} className="ml-1">
                <CancelBaseRate />
              </div>
            </div>
          ) : saved && rate.length > 0 ? (
            rate + "%"
          ) : (
            base_range
          )}
        </div>
        {saved && rate.length > 0 && !isEditing && isHovered && (
          <div className="flex flex-row cursor-pointer">
            {isHovered ? (
              <RateSavedIconHovered onClick={() => setRate("")} />
            ) : (
              <RateSavedIcon onClick={() => setRate("")} />
            )}
          </div>
        )}
      </div>
      <div>.</div>

      <div
        className={`${
          isHovered ? "text-white" : "text-[black]"
        } text-base col-span-2 p-1 flex items-center`}
      >
        {effective_range}
      </div>
      <div>.</div>

      <div
        className={`${
          isHovered
            ? saved && rate.length > 0
              ? "text-[#fbca5d]"
              : "text-white"
            : "text-[#3e2d8d]"
        } text-base font-extrabold col-span-2 mr-[10%] p-1 flex items-end`}
      >
        {repayment_range}
      </div>
    </div>
  ) : (
    <div
      className={`flex py-4 tracking-widest items-center ${
        isMobile && "flex-col"
      }`}
    >
      <div className="w-1/6 flex flex-col">
        <div className=" text-[#3e2d8d] font-bold">
          {title.company_name}
        </div>
        <div className="text-[#808080] font-bold text-sm">
          {title.product_name}
        </div>
      </div>
      <div className="flex w-1/6 h-1">
        <div className="w-1/2 border-t-2 border-[#3e2d8d] rounded flex flex-row"></div>
      </div>
      {/* <div
        className={`${
          isHovered ? "text-white" : "text-[black]"
        } text-base flex items-center w-1/6 font-bold font-grotesk`}
      >
        {title.rate_bracket_name}
      </div> */}
      <div
        className={`${
          isMobile
            ? "flex flex-col"
            : "w-2/3 grid grid-cols-3 gap-5 justify-items-end"
        }`}
      >
        {/* <div
          className={`${
            isHovered ? "text-white" : "text-[black]"
          } text-base flex items-center`}
        >
          {base_range}
        </div> */}
        <div className="flex flex-row">
          <div
            className={`${
              isHovered ? "text-white" : "text-[black]"
            } text-base ${
              isHovered && !isEditing && !saved && "hover:bg-[#514298]"
            } ${
              saved &&
              rate.length > 0 &&
              (isHovered && !isEditing
                ? " font-[700] decoration-dashed decoration-white underline"
                : "font-[700] decoration-dashed decoration-black underline")
            } p-1 flex flex-row hover:underline justify-center decoration-dashed decoration-[#fbca5d] underline-offset-4 rounded cursor-pointer`}
            onClick={() => {
              !isEditing && toggle();
            }}
          >
            {isEditing ? (
              <div className="flex flex-row items-center">
                <div
                  className={`border-2 bg-white border-yellow ${
                    !isEditing && "hover:bg-[#514298]"
                  } rounded p-1 flex flex-row`}
                >
                  <div className="text-black mx-2">%</div>
                  <input
                    className="bg-white border-0 p-0 w-20 text-black font-bold"
                    placeholder="Enter"
                    type="number"
                    onChange={(e) => {if(parseInt(e.target.value)<=100){setRateUnsaved(e.target.value)}}}
                    value={rateUnsaved}
                    autoFocus={true}
                  />
                </div>
                {rateUnsaved.length === 0 ? (
                  <div className="ml-1 cursor-default">
                    <UnfilledTick />
                  </div>
                ) : (
                  <div onClick={() => saveChanges()} className="ml-1">
                    <FilledTick />
                  </div>
                )}
                <div onClick={() => toggle()} className="ml-1">
                  <CancelBaseRate />
                </div>
              </div>
            ) : saved && rate.length > 0 ? (
              rate + "%"
            ) : (
              base_range
            )}
          </div>
          {saved && rate.length > 0 && !isEditing && (
            <div className="flex flex-row cursor-pointer mr-10">
              {isHovered ? (
                <RateSavedIconHovered
                  onClick={() => {
                    setRate("");
                    setSaved(false);
                    setRateUnsaved("");
                  }}
                />
              ) : (
                <RateSavedIcon
                  onClick={() => {
                    setRate("");
                    setSaved(false);
                    setRateUnsaved("");
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div
          className={`${
            isHovered ? "text-white" : "text-[black]"
          } text-base flex items-center`}
        >
          {effective_range}
        </div>
        <div
          className={`${
            isHovered ? "text-white" : "text-[#3e2d8d]"
          } text-base font-extrabold flex items-center`}
        >
          {repayment_range}
        </div>
      </div>
    </div>
  );
};

export default RateRow;
