import React, { FC } from "react";
import { Link } from "react-router-dom";

import { AppLayout } from "../app-layout";
import { SettingsCard } from "./settings-card";
import { ColorCard } from "../../../pages/shared-components/color-card";

import { ReactComponent as AccountIcon } from "../../../assets/icons/credit-card-dollar.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/single-neutral-actions-edit-1.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/icons/alarm-bell.svg";
import { ReactComponent as SecurityIcon } from "../../../assets/icons/lock-8.svg";

import SettingsArt from "../../../assets/illustrations/settings.svg";
import moment from "moment";

import { useSelector } from "react-redux";
import { userDetails } from "../../../redux/slices/userDataSlice";

export const SettingsPage = () => {
  const userData = useSelector(userDetails);

  return (
    <AppLayout title="Settings">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
          <ColorCard color="gray-500">
            <div className="text-center p-8 mb-4">
              <div className="text-3xl font-display mb-2">
                {userData.first_name + " " + userData.last_name}
              </div>
              <div>Joined in {moment(userData.created).format("MMM YYYY")}</div>
            </div>
            <img
              src={SettingsArt}
              alt=""
              className="w-full pointer-events-none"
            />
          </ColorCard>
        </div>
        <div className="w-full md:w-1/2 xl:w-2/3 md:px-4 mb-8 flex flex-wrap content-start">
          <Setting
            color="purple"
            icon={<ProfileIcon />}
            linkTo="/settings/profile"
            text="Update personal information like name and home country."
            title="Profile information"
          />
          {false && (
            <>
              <Setting
                color="green"
                icon={<AccountIcon />}
                linkTo="/settings/account"
                text="Review payment information, past invoices and transactions."
                title="Account summary"
              />
              <Setting
                color="yellow"
                icon={<NotificationIcon />}
                linkTo="/settings/notifications"
                text="Choose type of notifications you want to receive."
                title="Notification preferences"
              />
            </>
          )}
          <Setting
            color="coral"
            icon={<SecurityIcon />}
            linkTo="/settings/security"
            text="Change your password or permanently delete your account."
            title="Privacy &amp; security"
          />
        </div>
      </div>
    </AppLayout>
  );
};

type SettingType = {
  color: string;
  icon: React.ReactNode;
  linkTo: string;
  text: string;
  title: string;
};

const Setting: FC<SettingType> = ({ color, icon, linkTo, text, title }) => (
  <div className="w-full xl:w-1/2 px-4 mb-8">
    <Link to={linkTo} className="block">
      <SettingsCard color={color} icon={icon} text={text} title={title} />
    </Link>
  </div>
);
