import React, { useState, FC, useEffect, useRef } from "react";

interface Props {
  updateForm: (key: string, value: any) => void;
  type?: any;
}

const CompoundingFrequencyDropdown: FC<Props> = ({ updateForm, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    { label: "Yearly", value: "y" },
    { label: "Quarterly", value: "q" },
    { label: "Monthly", value: "m" },
    { label: "Weekly", value: "w" },
  ];
  const [selectedOption, setSelectedOption] = useState({
    label: "Select",
    value: "Select",
  });

  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (type) {
      const newSelectedOption = options.find((option) => option.value === type);
      if (newSelectedOption) {
        setSelectedOption(newSelectedOption);
      }
    }
  }, [type]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        listRef.current &&
        !listRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (data: { label: string; value: string }) => {
    setSelectedOption(data);
    toggleDropdown();
    updateForm("compounding_frequency", data.value);
  };

  return (
    <div className="relative w-full mx-2 pr-[4%]" ref={dropdownRef}>
      <div
        className={`rounded-md cursor-pointer bg-[#f2f2f2] appearance-none py-2 px-3 ${
          !isOpen
            ? `border-4 border-transparent text-black`
            : "border-[#463691] border-4"
        }`}
        onClick={toggleDropdown}
      >
        <div className="flex justify-between items-center">
          <span
            className={`${
              selectedOption.label !== "Select" ? "font-bold" : "opacity-70"
            }`}
          >
            {selectedOption.label}
          </span>
          <svg
            className={`w-4 h-4 transition-transform transform ${
              isOpen ? "-rotate-180" : "rotate-0"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <ul
          className="absolute z-10 bg-[#3e2d8d] rounded-md mb-1 w-[96%] bottom-full"
          ref={listRef}
        >
          {options.map((data: any, index) => (
            <React.Fragment key={index}>
              <li
                className="cursor-pointer p-4 hover:rounded mx-1 my-1 hover:bg-black text-white hover:text-[#dcb151] font-[700]"
                onClick={() => handleSelect(data)}
              >
                {data.label}
              </li>
              {index !== options.length - 1 && (
                <div className="mx-3 border-b border-gray-500" />
              )}
            </React.Fragment>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CompoundingFrequencyDropdown;
