import React, { useState, useCallback, FC, useEffect, useRef } from "react";
import { ReactComponent as Plus } from "../../../../icons/plus.svg";
import { ReactComponent as PlusWhite } from "../../../../icons/plus_white.svg";
import { useSelector } from "react-redux";
import { directoryState as ds } from "../../../../redux/slices/directorySlice";
import {
  createProducts,
  updateProductData,
} from "../../../../services/products.service";
import { customToast } from "../../../shared-components/custom-toast";
import CompoundingFrequencyDropdown from "./customDropdown/compoundingFreqDropDown";
import { HorizontalDivider } from "../../form-components/divider";
import { useMediaQuery } from "react-responsive";
import TooltipCustom from "./Tooltip";
import { ReactComponent as Star } from "../../../../icons/star.svg";
import { ReactComponent as DeleteIcon } from "../../../../icons/delete_outlined.svg";

interface Props {
  componentType: string;
  setReloadData: any;
  reloadData: boolean;
  hovered?: boolean;
  isLoading:boolean;
  setShowModal: any;
  showModal: boolean;
  setShowDeleteModal: any;
}

const ModalComponent: FC<Props> = ({
  componentType,
  setReloadData,
  reloadData,
  hovered,
  isLoading,
  setShowModal,
  showModal,
  setShowDeleteModal
}) => {
  const directoryState = useSelector(ds);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });
  const parentRef = useRef(null);
  const modalRef = useRef(null);
  const [marginTopValue, setMarginTopValue] = useState(0);
  const initialValues = {
    product_id: null,
    product_name: null,
    min_range: null,
    max_range: 0,
    min_amount: 0,
    max_amount: 0,
    monthly_fee: null,
    monthly_fee_gst: null,
    documentation_fee: null,
    documentation_fee_gst: null,
    brokerage_cap: null,
    brokerage_cap_gst: null,
    maximum_brokerage_limit: null,
    maximum_brokerage_limit_gst: null,
    compounding_frequency: 0,
    brokerage_loading_treatment: 0,
    type: null,
    company_id: directoryState.selectedComapnyId || null,
  };
  const [formData, setFormData] = useState(initialValues);
  useEffect(() => {
    if (componentType && componentType !== "add" && directoryState) {
      const { id, ...payload } = directoryState.productsData;
      setFormData({ ...payload, product_id: id });
    }
  }, [directoryState, componentType]);
  const updateForm = (key: any, value: any) => {
    setFormData({ ...formData, [key]: value });
  };
  const handleSave = () => {
    if (formData.product_name) {
      const payload = {
        ...formData,
        min_amount: Number(formData["min_amount"]),
        min_range: Number(formData["min_range"]),
        max_amount: Number(formData["max_amount"]),
        max_range: Number(formData["max_range"]),
      };
      if (componentType === "add") {
        createProducts(payload).then(successCallback).catch(errorCallback);
      } else {
        updateProductData(payload).then(successCallback).catch(errorCallback);
      }
    } else {
      errorCallback({
        response: {
          data: { detail: "Incomplete form details." },
        },
      });
    }
  };
  const successCallback = useCallback((res: any) => {
    customToast({ message: res.data.message, type: "success" });
    setFormData(initialValues);
    setReloadData(!reloadData);
    setShowModal(false);
  }, []);

  const errorCallback = useCallback((error: any) => {
    customToast({
      message: `${error.response.data.detail || "Something went wrong"}`,
      type: "error",
    });
  }, []);

  useEffect(()=>{
    if(parentRef.current && modalRef.current){
      //@ts-ignore
      if((parentRef.current.clientHeight - modalRef.current.clientHeight)<0){
        //@ts-ignore
        setMarginTopValue(modalRef.current.clientHeight - parentRef.current.clientHeight);
      }
    }
  }, [parentRef, modalRef, showModal])

  const isDisabled = () => {
    if (
      !formData?.product_name ||
      !formData?.compounding_frequency ||
      (formData?.min_amount ? Number(formData.min_amount) : 0) > (formData?.max_amount?  Number(formData?.max_amount) : 0) ||
      (formData?.min_range ? Number(formData.min_range) : 0) > (formData?.max_range?  Number(formData?.max_range) : 0)
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
      {componentType === "add" ? (
        <button
          className={`mr-[2%] border-2 border-opacity-2 ${
            hovered ? "border-[#6457a4]" : "border-[#c9a24a]"
          } rounded-md cursor-pointer p-[0.8%]`}
          type="button"
          onClick={() => !isLoading && setShowModal(true)}
        >
          {hovered ? <PlusWhite /> : <Plus />}
        </button>
      ) : (
        <div
          className={`border-2 ${
            hovered
              ? "border-[#6457a4] text-white"
              : "border-[#c9a24a] text-black"
          } font-[700] px-4 py-2 rounded-md cursor-pointer`}
          onClick={() => !isLoading && setShowModal(true)}
        >
          Edit
        </div>
      )}
      {showModal ? (
        <>
          <div
            className={`justify-center items-center flex overflow-x-hidden text-black overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}
            ref={parentRef}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl" ref={modalRef} style={{marginTop: `${marginTopValue-40}px`}}>
              <div
                className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full ${
                  isSmallScreen && "mt-[20%]"
                } py-8 px-4 bg-white outline-none focus:outline-none`}
              >
                <div className="flex items-start justify-between p-4 px-8 mb-3 rounded-t">
                  <div
                    className={`flex ${
                      isSmallScreen ? "flex-col" : "flex-row"
                    } justify-between w-full items-center my-2`}
                  >
                    <h3 className="text-3xl font-semibold font-grotesk">
                      {componentType === "add" ? "Add" : "Edit"} Product
                    </h3>
                    <div
                      className={`flex ${
                        isSmallScreen ? "flex-col" : "flex-row"
                      }`}
                    >
                      {componentType !== "add" && (
                        <button
                          className="border-2 border-[#cccccc] px-4 rounded-md py-2 font-bold text-base ml-4"
                          type="button"
                          onClick={() => {
                            setShowDeleteModal(true);
                            setShowModal(false);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      <button
                        className={` border-2 border-[#cccccc] justify-center flex items-center rounded-md py-2 px-8 font-bold text-base ${
                          isSmallScreen ? "my-2" : "mx-4"
                        }`}
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={`${
                          isDisabled() ? "bg-[#c5c0dd]" : "bg-[#3e2d8d]"
                        } text-white flex justify-center items-center font-bold px-10 py-2 rounded-md text-base`}
                        onClick={() =>
                          !isDisabled() ? handleSave() : () => {}
                        }
                        disabled={isDisabled()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                {!isSmallScreen && (
                  <div className="mx-8">
                    <HorizontalDivider />
                  </div>
                )}
                <div className="relative p-5 mt-3 pb-9 flex-auto">
                  <div className="mx-2 opacity-90 flex flex-row">Name <Star className="mx-1"/></div>
                  <input
                    placeholder="Enter"
                    className="bg-[#f2f2f2] p-3 font-bold w-[98%] rounded-md mx-2 placeholder:font-normal"
                    value={formData.product_name || ""}
                    onChange={(e) => updateForm("product_name", e.target.value)}
                  />
                  <div
                    className={`flex ${
                      isSmallScreen ? "flex-col" : "flex-row"
                    } pt-7`}
                  >
                    <div className="flex flex-col w-full">
                      <div className="mx-2 mt-2 opacity-90">Amount Range</div>
                      <div className="flex flex-row">
                        <div
                          className={`border-transparent flex relative bg-[#f2f2f2] flex-row border-[3px] rounded items-center p-3 mx-2 w-full`}
                        >
                          <div className="mx-1 opacity-60">Min</div>
                          <div className="opacity-60">$</div>
                          <input
                            type="number"
                            placeholder="Enter"
                            className="bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal"
                            value={
                              Number(formData.min_amount) === 0
                                ? ""
                                : Number(formData.min_amount)
                            }
                            onChange={(e) =>
                              updateForm("min_amount", e.target.value)
                            }
                          />
                        </div>
                        <div
                          className={`${
                            (formData.min_amount ? Number(formData.min_amount) : 0) >
                            Number(formData.max_amount)
                              ? "border-[#f74343]"
                              : " border-transparent"
                          } flex relative bg-[#f2f2f2] flex-row border-[3px] rounded items-center p-3 mx-2 w-full`}
                        >
                          {(formData.min_amount ? Number(formData.min_amount) : 0) >
                            Number(formData.max_amount) && <TooltipCustom />}
                          <div className="mx-1 opacity-60">Max</div>
                          <div className="opacity-60">$</div>
                          <input
                            type="number"
                            placeholder="Enter"
                            className={`bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal align-middle ${
                              (formData.min_amount ? Number(formData.min_amount) : 0) >
                                Number(formData.max_amount) && "text-[#f74343]"
                            }`}
                            value={
                              Number(formData.max_amount) === 0
                                ? ""
                                : Number(formData.max_amount)
                            }
                            onChange={(e) =>
                              updateForm("max_amount", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="mx-2 mt-10 opacity-90">Monthly Fees</div>
                      <div className="flex flex-row p-3 mx-2 rounded bg-[#f2f2f2]">
                        <div className="mx-1 opacity-70">$</div>
                        <input
                          placeholder="Enter"
                          type="number"
                          className="border-0 font-[700] p-0 bg-[#f2f2f2] placeholder:font-normal"
                          value={
                            Number(formData.monthly_fee) === 0
                              ? ""
                              : Number(formData.monthly_fee)
                          }
                          onChange={(e) =>
                            updateForm("monthly_fee", e.target.value)
                          }
                        />
                      </div>
                      <div className="mx-2 mt-10 opacity-90">Brokerage Cap</div>
                      <div className="flex flex-row p-3 mx-2 rounded bg-[#f2f2f2]">
                        <div className="mx-1 opacity-70">%</div>
                        <input
                          placeholder="Enter"
                          type="number"
                          className="border-0 font-[700] p-0 bg-[#f2f2f2] placeholder:font-normal"
                          value={
                            Number(formData.brokerage_cap) === 0
                              ? ""
                              : Number(formData.brokerage_cap)
                          }
                          onChange={(e) =>
                            updateForm("brokerage_cap", e.target.value)
                          }
                        />
                      </div>
                      <div className="mx-2 mt-10 opacity-90">
                        Brokerage Loading Treatment
                      </div>
                      <div className="flex flex-row rounded p-3 mx-2 bg-[#f2f2f2] font-normal">
                        <div className="mx-1 opacity-70">%</div>
                        <input
                          placeholder="Enter"
                          type="number"
                          className="border-0 font-[700] p-0 bg-[#f2f2f2] placeholder:font-normal"
                          value={
                            Number(formData.brokerage_loading_treatment) === 0
                              ? ""
                              : Number(formData.brokerage_loading_treatment)
                          }
                          onChange={(e) =>
                            updateForm(
                              "brokerage_loading_treatment",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={`flex flex-col w-full ${
                        !isSmallScreen && "ml-9"
                      }`}
                    >
                      <div className="mx-2 mt-2 opacity-90">Term Range</div>
                      <div className="flex flex-row">
                        <div
                          className={`border-transparent flex relative bg-[#f2f2f2] flex-row border-[3px] rounded items-center p-3 mx-2 w-full`}
                        >
                          <div className="mx-1 opacity-60">Min</div>
                          <div className="opacity-60">$</div>
                          <input
                            type="number"
                            placeholder="Enter"
                            className="bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal"
                            value={
                              Number(formData.min_range) === 0
                                ? ""
                                : Number(formData.min_range)
                            }
                            onChange={(e) =>
                              updateForm("min_range", e.target.value)
                            }
                          />
                        </div>
                        <div
                          className={`${
                            (formData.min_range ? Number(formData.min_range) : 0) >
                            Number(formData.max_range)
                              ? "border-[#f74343]"
                              : " border-transparent"
                          } flex relative bg-[#f2f2f2] flex-row border-[3px] rounded items-center p-3 mx-2 w-full`}
                        >
                          {(formData.min_range ? Number(formData.min_range) : 0) >
                            Number(formData.max_range) && <TooltipCustom />}
                          <div className="mx-1 opacity-60">Max</div>
                          <div className="opacity-60">$</div>
                          <input
                            type="number"
                            placeholder="Enter"
                            className={`bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal ${
                              (formData.min_range ? formData.min_range : 0) >
                                formData.max_range && "text-[#f74343]"
                            }`}
                            value={
                              Number(formData.max_range) === 0
                                ? ""
                                : Number(formData.max_range)
                            }
                            onChange={(e) =>
                              updateForm("max_range", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="mx-2 mt-10 opacity-90">
                        Documentation Fees
                      </div>
                      <div className="flex flex-row p-3 mx-2 rounded bg-[#f2f2f2]">
                        <div className="mx-1 opacity-60">$</div>
                        <input
                          placeholder="Enter"
                          type="number"
                          className="border-0 p-0 font-[700] bg-[#f2f2f2] placeholder:font-normal"
                          value={
                            Number(formData.documentation_fee) === 0
                              ? ""
                              : Number(formData.documentation_fee)
                          }
                          onChange={(e) =>
                            updateForm("documentation_fee", e.target.value)
                          }
                        />
                      </div>
                      <div className="mx-2 mt-10 opacity-90">
                        Maximum Brokerage Limit
                      </div>
                      <div className="flex flex-row p-3 mx-2 rounded bg-[#f2f2f2]">
                        <div className="mx-1 opacity-60">%</div>
                        <input
                          placeholder="Enter"
                          type="number"
                          className="border-0 p-0 font-[700] bg-[#f2f2f2] placeholder:font-normal"
                          value={
                            Number(formData.maximum_brokerage_limit) === 0
                              ? ""
                              : Number(formData.maximum_brokerage_limit)
                          }
                          onChange={(e) =>
                            updateForm(
                              "maximum_brokerage_limit",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="mx-2 mt-10 opacity-90 flex flex-row">
                        Compounding frequency <Star className="mx-1"/>
                      </div>
                      {componentType !== "add" ? (
                        <CompoundingFrequencyDropdown
                          updateForm={updateForm}
                          type={
                            directoryState.productsData.compounding_frequency
                          }
                        />
                      ) : (
                        <CompoundingFrequencyDropdown updateForm={updateForm} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalComponent;
