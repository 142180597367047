import React, { useState } from "react";

import { SettingsLayout } from "./settings.layout";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/single-neutral-actions-edit-1.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserProfile,
  userDetails,
} from "../../../redux/slices/userDataSlice";
import { useForm } from "react-hook-form";
import {
  getUserDetailsViaToken,
  updateProfile,
} from "../../../services/profile.service";

export const ProfileSettingsPage = () => {
  const [alert, setAlert] = useState({ isSuccess: false, message: "" });
  const userData = useSelector(userDetails);
  const dispatch = useDispatch();

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      email: userData.email,
      first_name: "",
      last_name: "",
    },
  });

  const validateForm = () =>
    watch("first_name").length && watch("last_name").length;

  const handleProfileUpdate = (params: any) => {
    setAlert({ isSuccess: false, message: "" });
    updateProfile(userData.id, {
      first_name: params.first_name,
      last_name: params.last_name,
    })
      .then(() => {
        setAlert({
          isSuccess: true,
          message: "Profile information updated :)",
        });
        getUserDetailsViaToken()?.then((res) =>
          dispatch(updateUserProfile({ userData: res, hasProfile: true }))
        );
      })
      .catch((e) => setAlert({ isSuccess: false, message: e.message }));
  };

  return (
    <SettingsLayout
      color="purple"
      icon={<ProfileIcon />}
      text="Update personal information like name and home country."
      title="Profile information"
    >
      <div className="bg-white rounded border border-gray-400 p-8 md:p-12">
        <div className="font-semibold border-b border-gray-200 pb-2 mb-6">
          Update profile
        </div>
        <form
          className="mx-auto max-w-full lg:max-w-md"
          onSubmit={handleSubmit(handleProfileUpdate)}
        >
          <fieldset className="mb-6">
            <label className="block mb-1" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              type="email"
              className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Email"
              value={userData.email}
              disabled
            />
          </fieldset>
          <fieldset className="mb-6">
            <label className="block mb-1" htmlFor="name">
              First Name
            </label>
            <input
              id="first_name"
              type="text"
              className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder={userData.first_name || "First Name"}
              {...register("first_name", {
                required: "This input is required.",
              })}
            />
          </fieldset>
          <fieldset className="mb-6">
            <label className="block mb-1" htmlFor="name">
              Last Name
            </label>
            <input
              id="last_name"
              type="text"
              className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder={userData.last_name || "Last Name"}
              {...register("last_name", {
                required: "This input is required.",
              })}
            />
          </fieldset>
          <fieldset className="text-right">
            <input
              className="w-full md:w-auto bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
              type="submit"
              disabled={!validateForm()}
              value="Update"
            />
            <div
              className={`text-center text-sm px-4 py-3 rounded mt-4 ${
                alert.message.length > 0 ? "" : "hidden"
              } ${
                alert.isSuccess
                  ? "bg-blue-100 border border-blue-400 text-blue-600"
                  : "bg-red-100 border border-red-400 text-red-600"
              }`}
            >
              {alert ? alert.message : ""}
            </div>
          </fieldset>
        </form>
      </div>
    </SettingsLayout>
  );
};
