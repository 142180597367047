import {default as axios} from "axios";
import {RESET_PASSWORD_URL} from "../api.routes";

export interface ResetPasswordPayload {
  uid: string
  token: string
  password: string
  confirmPassword: string
}

export interface ResetPasswordResponse {
  message: string
  status: number
}

const resetPasswordService = async ({uid, token, ...rest}: ResetPasswordPayload) => {
  return await axios.post<ResetPasswordResponse>(
    `${RESET_PASSWORD_URL}/${uid}/${token}/`,
    {
      ...rest,
      confirm_password: rest.confirmPassword
    }
  );
}

export default resetPasswordService;

