import * as ls from "local-storage";

export const authHeader: any = () => {
  const accessToken = ls.get("accessToken");
  if (accessToken) {
    return { Authorization: "Bearer " + accessToken };
  } else {
    return { Authorization: "" };
  }
};
