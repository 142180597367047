import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { customToast } from "../shared-components/custom-toast";

import LogoImage from "../../assets/images/logo.svg";
import { ReactComponent as LoadingImage } from "../../assets/images/loading.svg";
import { useQuery } from "../../hooks/use-query";
import { emailVerify } from "../../services/email-verify.service";

export const EmailVerifyPage = () => {
  const [isSuccessfulVerification, setIsSuccessfulVerification] =
    useState(false);
  const [message, setMessage] = useState(null);

  const query = useQuery();
  const navigate = useNavigate();

  const emailVerifySuccessCallback = useCallback(
    (res: any) => {
      setIsSuccessfulVerification(true);
      var success_message = res.data.email ? res.data.email : "Email Verified"
      success_message = res.data.email + ". Redirecting to login page."
      setMessage(success_message);
      setTimeout(function () {
        // customToast({
        //   message: res.data.email ? res.data.email : "Email Verified",
        //   type: "success",
        // });
        navigate("/login", { replace: true });
      }, 4000);
    },
    [query.token]
  );

  const emailVerifyErrorCallback = useCallback(
    (error: any) => {
      setIsSuccessfulVerification(false);
      var error_message = error.response.data.error ? error.response.data.error : "Something went wrong"
      error_message = error_message + ". Redirecting to home page."
      setMessage(error_message);
      setTimeout(function () {
        // customToast({
        //   message: error.response.data.error ? error.response.data.error : "Something went wrong",
        //   type: "error",
        // });
        navigate("/", { replace: true });
      }, 4000);
    },
    [query.token]
  );

  useEffect(() => {
    setTimeout(function () {
      query.token &&
        emailVerify(query.token as string)
          .then((emailVerifySuccessCallback))
          .catch(emailVerifyErrorCallback);
    }, 2000);
  }, [query.token]);

  return (
    <div className="flex flex-col min-h-screen bg-white pointer-events-none">
      <div className="flex flex-col flex-grow pt-12 space-y-16">
        <div>
          <img className="mx-auto" src={LogoImage} alt="Finput" />
        </div>

        <div>
          <div
            className={`w-fit text-lg font-medium  mx-auto ${
              message === null
                ? "text-primary"
                : isSuccessfulVerification
                ? "text-emerald-700"
                : "text-red-700"
            }`}
          >
            {message === null ? (
              <span>Email verification started. Please wait</span>
            ) : isSuccessfulVerification ? (
              <span>{message}</span>
            ) : (
              <span>{message}</span>
            )}
          </div>
          <div className="w-12 mx-auto text-primary pt-2">
            <LoadingImage className="block w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};
