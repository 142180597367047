import React, { useState, useCallback, FC, useEffect } from "react";
import { ReactComponent as Plus } from "../../../../icons/plus.svg";
import { ReactComponent as PlusWhite } from "../../../../icons/plus_white.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  directoryState as ds,
  updateBracketData,
  updateUpliftData,
} from "../../../../redux/slices/directorySlice";
import { customToast } from "../../../shared-components/custom-toast";
import { HorizontalDivider } from "../../form-components/divider";
import {
  addRateBrackets,
  updateRateBracketData,
} from "../../../../services/rate-brackets.service";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as Star } from "../../../../icons/star.svg";
import { ReactComponent as DeleteIcon } from "../../../../icons/delete_outlined.svg";

interface Props {
  type: String;
  hovered: boolean;
  setReloadData: any;
  reloadData: any;
  isLoading: boolean;
  showModal: boolean;
  setShowModal: any;
  setShowDeleteModal: any;
}

const AddBracketModal: FC<Props> = ({
  type,
  hovered,
  setReloadData,
  reloadData,
  isLoading,
  setShowModal,
  showModal,
  setShowDeleteModal
}) => {
  const directoryState = useSelector(ds);
  const dispatch = useDispatch();
  useEffect(()=>{
    setShowDeleteModal(false);
  }, [])
  interface InitialValues {
    id?: number | null;
    bracket_name: string | null;
    base_rate: number | null;
    product_id: number | null;
    bracket_id?: number | null;
  }
  const initialValues: InitialValues = {
    bracket_name: null,
    base_rate: null,
    product_id: null,
  };
  const [formData, setFormData] = useState(initialValues);
  const updateForm = (key: any, value: any) => {
    setFormData({ ...formData, [key]: value });
  };
  const resetForm = () => {
    setFormData(initialValues);
  }
  useEffect(() => {
    if (type && type !== "add") {
      const { created_at, updated_at, id, ...payload } =
        directoryState.bracketData;
      setFormData({ bracket_id: id, ...payload });
    }
  }, [type,showModal]);
  const handleSave = () => {
    if (formData.bracket_name && formData.base_rate) {
      const payload = {
        ...formData,
        product_id: directoryState.selectedProductId,
      };
      if (type === "add") {
        addRateBrackets(payload).then(successCallback).catch(errorCallback);
      } else {
        updateRateBracketData(payload)
          .then(successCallback)
          .catch(errorCallback);
      }
    } else {
      errorCallback({
        response: {
          data: { detail: "Incomplete form details." },
        },
      });
    }
  };
  const successCallback = useCallback((res: any) => {
    customToast({ message: res.data.message, type: "success" });
    setShowModal(false);
    setReloadData(!reloadData);
    dispatch(updateBracketData(null));
    dispatch(updateUpliftData(null));
  }, []);

  const errorCallback = useCallback((error: any) => {
    customToast({
      message: `${error.response.data.error || "Something went wrong"}`,
      type: "error",
    });
  }, []);
  const isDisabled = () => {
    if (!formData.base_rate || !formData.bracket_name) {
      return true;
    }
    return false;
  };
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });
  return (
    <>
      {type !== "add" ? (
        <div
          className={`border-2 ${
            hovered
              ? "border-[#6457a4] text-white"
              : "border-[#c9a24a] text-black"
          } font-[700] px-4 py-2 rounded-md cursor-pointer`}
          onClick={() => !isLoading && setShowModal(true)}
        >
          Edit
        </div>
      ) : (
        <button
          className={`mr-[2%] border-2 border-opacity-2 ${
            hovered ? "border-[#6457a4]" : "border-[#c9a24a]"
          } rounded-md cursor-pointer p-[0.8%]`}
          type="button"
          onClick={() => !isLoading && setShowModal(true)}
        >
          {hovered ? <PlusWhite /> : <Plus />}
        </button>
      )}
      {showModal ? (
        <>
          <div className="justify-center items-center flex text-black overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-4 px-8 rounded-t">
                  <div
                    className={`flex ${
                      isSmallScreen ? "flex-col" : "flex-row"
                    } justify-between w-full items-center my-2`}
                  >
                    <h3 className="text-3xl font-semibold font-grotesk">
                      {type === "add" ? "Add" : "Edit"} Bracket
                    </h3>
                    <div className="flex flex-row">
                      {type !== "add" && (
                        <button
                          className="border-2 border-[#cccccc] px-4 rounded-md py-2 font-bold text-base ml-4"
                          type="button"
                          onClick={() => {
                            setShowDeleteModal(true);
                            setShowModal(false);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      <button
                        className=" border-2 border-[#cccccc] rounded-md py-2 px-6 font-bold text-base mx-4"
                        onClick={() => {setShowModal(false); type==="add" && resetForm();}}
                      >
                        Cancel
                      </button>
                      <button
                        className={`${
                          isDisabled() ? "bg-[#c5c0dd]" : "bg-[#3e2d8d]"
                        } text-white font-bold px-8 py-2 rounded-md text-base`}
                        onClick={() =>
                          !isDisabled() ? handleSave() : () => {}
                        }
                        disabled={isDisabled()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mx-8">
                  <HorizontalDivider />
                </div>
                <div className="relative p-5 flex-auto">
                  <div className="mx-2 flex flex-row">
                    Name <Star className="mx-1" />
                  </div>
                  <input
                    placeholder="Enter"
                    className="bg-[#f2f2f2] p-3 font-bold w-[98%] rounded-md mx-2 placeholder:font-normal"
                    value={formData.bracket_name || ""}
                    onChange={(e) => updateForm("bracket_name", e.target.value)}
                  />
                  <div className="mx-2 mt-2 flex flex-row">
                    Base Rate (%) <Star className="mx-1" />
                  </div>
                  <input
                    placeholder="Enter"
                    className="bg-[#f2f2f2] p-3 border-none font-bold w-[98%] rounded-md mx-2 mb-2 placeholder:font-normal"
                    value={formData.base_rate || ""}
                    type="number"
                    onChange={(e) => updateForm("base_rate", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AddBracketModal;
