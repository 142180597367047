import * as ls from "local-storage";
import jwt from "jwt-decode";

export function getAndDecodeAccessToken(): any {
  const accessToken: string | undefined = ls.get("accessToken");
  return accessToken ? jwt(accessToken) : null;
}

export function isTokenValid(token: string | null) {
  if (token) {
    const decodedData: any = jwt(token);

    if (decodedData) {
      if (Date.now() >= decodedData["exp"] * 1000) {
        return false; // expired
      } else {
        return true; // valid
      }
    }
  }
  return false; // expired
}
