import { default as axios } from "axios";
import { PRODUCTS_API_URL, PRODUCTS_COMPANY_API_URL } from "../api.routes";
import { authHeader } from "../auth.header";

export async function createProducts(params: object) {
  return await axios.post(PRODUCTS_API_URL, params, {
    headers: authHeader(),
  });
}

export async function getProductsOfCompany(company_id: string) {
  return await axios.get(PRODUCTS_COMPANY_API_URL + company_id, {
    headers: authHeader(),
  });
}

export async function getProduct(product_id: number) {
  return await axios.get(PRODUCTS_API_URL + product_id, {
    headers: authHeader(),
  });
}

export async function updateProductData(params: any) {
  return await axios.patch(PRODUCTS_API_URL + params.product_id + "/", params, {
    headers: authHeader(),
  });
}

export async function deleteProduct(product_id:number){
  return await axios.delete(PRODUCTS_API_URL + product_id + "/", {
    headers: authHeader(),
  });
}
