import React, {
  FC,
  useMemo,
  useState,
  useCallback,
  PropsWithChildren,
  useEffect,
} from "react";

import { useMediaQuery } from "../../../hooks/use-media-query";

import { Logo } from "../../../pages/shared-components/logo";
import LogoPatternImage from "../../../assets/images/logo-pattern.png";
import { ReactComponent as OpenSidebarIcon } from "../../../icons/open_sidebar.svg";
import { ReactComponent as CloseSidebarIcon } from "../../../icons/close_sidebar.svg";
import { ReactComponent as CalculatorIcon } from "../../../icons/calclulator_icon.svg";
import { ReactComponent as CalculatorIconAcive } from "../../../icons/calculator_filled.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/cog.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/power-button.svg";
import { ReactComponent as MemoriesIcon } from "../../../assets/icons/picture-polaroid.svg";
import { ReactComponent as Directory } from "../../../icons/directory.svg";
import { ReactComponent as DirectoryFilled } from "../../../icons/directory_active.svg";
import { ReactComponent as UserIcon } from "../../../icons/user.svg";
import { ReactComponent as Rating } from "../../../icons/rating_icon.svg";
import { ReactComponent as Report } from "../../../icons/report_issue.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout, userDetails } from "../../../redux/slices/userDataSlice";

import SidebarLink from "./sidebarLink";
import { setIsSideBarVisible } from "../../../redux/slices/directorySlice";

const styleHeight72 = { height: "72px" };
const styleMaxWidth110 = { maxWidth: "110px" };

export const Sidebar = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userDetails);

  // Initialize an array to store permission names
  const permissionName: string[] = []; // Explicitly define the type as 'string[]'

  // Iterate through the user's groups to extract permission names
  userData.groups.forEach((group: { permissions: any; name: string }) => {
    // Loop through each permission within the group
    group.permissions.forEach((permission: any) => {
      // Extract the codename of the permission
      const permissionCodename = permission.codename;

      // Add the extracted codename to the permissionName array
      permissionName.push(permissionCodename);
    });
  });

  const isMobile = useMediaQuery("(max-width: 960px)");
  const [isSidebarVisible, toggleSidebar] = useState(!isMobile);

  useEffect(() => {
    toggleSidebar(!isMobile);
    dispatch(setIsSideBarVisible(!isMobile))
  }, [isMobile]);

  const toggle = useCallback(() => {
    toggleSidebar(!isSidebarVisible);
    dispatch(setIsSideBarVisible(!isSidebarVisible))
  }, [isSidebarVisible, toggleSidebar]);

  const sidebarStyles = useMemo(
    () => ({
      backgroundImage: `url(${LogoPatternImage})`,
      backgroundRepeat: "repeat",
      marginLeft: isSidebarVisible ? 0 : "-572px",
      display: !isSidebarVisible ? "none" : "",
      width: "240px",
      transition: "display margin-left 0.4s ease-in-out",
    }),
    [isSidebarVisible]
  );

  return (
    <>
      <div
        className={`absolute left-0 z-20 ${
          isSidebarVisible ? "hidden" : "block"
        }`}
      >
        <div className="inline-flex items-center mx-8" style={styleHeight72}>
          <OpenSidebarIcon onClick={toggle} className=" cursor-pointer" />
        </div>
      </div>
      {
        <div
          className="flex flex-col h-full bg-white relative overflow-hidden rounded-xl"
          style={sidebarStyles}
        >
          <div
            className="flex items-center justify-between mx-6"
            style={styleHeight72}
          >
            <div style={styleMaxWidth110}>
              <Logo useLight={false} />
            </div>
            <CloseSidebarIcon onClick={toggle} className="cursor-pointer" />
          </div>

          <SidebarLinkGroup>
            <SidebarLink
              text="Calculator"
              to="/calculator"
              icon={<CalculatorIconAcive />}
              activeIcon={<CalculatorIcon />}
            />
            {permissionName.includes("add_company") && (
              <SidebarLink
                text="Organization"
                to="/organization"
                icon={<Directory />}
                activeIcon={<DirectoryFilled />}
              />
            )}

            {permissionName.includes("add_logentry") && (
              <SidebarLink
                text="Logs"
                to="/logs"
                icon={<MemoriesIcon />}
                isLocked={true}
                activeIcon={<MemoriesIcon className="fill-[#3e2d8d]" />}
              />
            )}

            {permissionName.includes("add_customuser") && (
              <SidebarLink
                text="Add User"
                to="/add-user"
                icon={<UserIcon />}
                isLocked={false}
                activeIcon={<UserIcon className="fill-[#3e2d8d]" />}
              />
            )}
            <SidebarLink
              text="Settings"
              to="/settings"
              icon={<SettingsIcon />}
              activeIcon={<SettingsIcon className="fill-[#3e2d8d]" />}
            />
            <SidebarLink
              text="Logout"
              to="/"
              icon={<LogoutIcon />}
              onClick={() => {
                dispatch(logout());
              }}
              activeIcon={<LogoutIcon className="fill-[#3e2d8d]" />}
            />
          </SidebarLinkGroup>
          <Invite />
        </div>
      }
    </>
  );
};

const SidebarLinkGroup: FC<PropsWithChildren> = ({ children }) => (
  <div className="border-white border-opacity-10 pt-20 py-6">
    {children}
  </div>
);

const styleMinHeight1 = { minHeight: "1px" };
const stylePaddingBottom240 = { paddingBottom: "240px" };
const ScrollArea: FC<PropsWithChildren> = ({ children }) => (
  <div className="flex-auto relative overflow-hidden" style={styleMinHeight1}>
    <div
      className="absolute left-0 right-0 bottom-0 top-0 overflow-y-auto"
      style={styleMinHeight1}
    >
      <div className="relative min-h-full" style={stylePaddingBottom240}>
        {children}
      </div>
    </div>
  </div>
);

const Invite = () => (
  <div className="flex-1 flex text-black text-sm text-start">
    <div className="flex flex-col items-start justify-end m-6">
      <Rating className="my-4" />
      <div className="mb-4 opacity-75">
        Do you like Finput? <br />
        Provide feedback for discount coupon :)
      </div>
      <a
        href="mailto:?body=Hey%2C%20you%27ve%20been%20invited%20to%20join%20Finput%20-%20https%3A%2F%2Ffinput.com.au&subject=You%27ve%20been%20invited%20to%20Finput"
        rel="noopener noreferrer"
        target="_blank"
        className="text-[#3e2d8d] font-bold"
      >
        Send Feedback
      </a>
      <a
        href="mailto:support@finput.com.au?subject=Report issue"
        className="flex items-center py-2 text-sm tracking-wide mb-3 last:mb-0 text-black font-bold "
      >
        <div className="w-5">
          <Report />
        </div>
        Report issue
      </a>
    </div>
  </div>
);
