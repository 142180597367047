import React, { FC } from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="relative bg-white px-4 py-10 lg:py-20">
      <div className="max-w-lg mx-auto">
        <hr className="mt-8" />
        <div className="block sm:flex items-center justify-center text-center py-1">
          <FooterLink to="/privacy" text="Privacy" />
          <FooterLink to="/terms" text="Terms of use" />
          <FooterLink to="/about" text="About" />
        </div>
        <hr className="mb-6" />
        <Copyright />
      </div>
    </footer>
  );
};

const FooterLink: FC<{ to: string; text: string }> = ({ to, text }) => (
  <div className="m-3">
    <Link to={to}>{text}</Link>
  </div>
);

const Copyright = () => (
  <div className="p-4 text-center opacity-50">
    &copy; {new Date().getFullYear()} Expa Pty Ltd.
  </div>
);
