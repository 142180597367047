import React, { useState, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import { AuthLayout } from "./login.layout";
import {
  getUserDetailsViaToken,
  updateProfile,
} from "../../services/profile.service";
import { getAndDecodeAccessToken } from "../../services/accessToken.service";
import {
  logout,
  updateUserProfile,
  userProfileStatus,
  userStatus,
} from "../../redux/slices/userDataSlice";
import { customToast } from "../shared-components/custom-toast";
import { useDispatch, useSelector } from "react-redux";

export const ProfilePage = () => {
  const userIsLoggedIn = useSelector(userStatus);
  const userHasProfile = useSelector(userProfileStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
    },
  });

  const [profileError, setProfileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () =>
    watch("first_name").length && watch("last_name").length;

  const successCallback = useCallback(
    (res: any) => {
      setIsLoading(false);
      customToast(res.data.message);
      getUserDetailsViaToken()?.then((res) =>
        dispatch(updateUserProfile({ userData: res, hasProfile: true }))
      );
      navigate("/", { replace: true });
    },
    [navigate, dispatch]
  );

  const errorCallback = useCallback((error: any) => {
    setIsLoading(false);
    setProfileError(error || "Something went wrong");
  }, []);

  const handleProfileUpdate = (params: any) => {
    setIsLoading(true);
    setProfileError("");

    const decodedData = getAndDecodeAccessToken();

    if (decodedData) {
      updateProfile(decodedData["user_id"], {
        first_name: params.first_name,
        last_name: params.last_name,
      })
        .then(successCallback)
        .catch(errorCallback);
    } else {
      customToast({
        message: "Session Expired. Login Again.",
        type: "error",
      });
      dispatch(logout());
      navigate("/", { replace: true });
    }
  };

  if (!userIsLoggedIn) return <Navigate to="/login" replace={true} />;
  else if (userIsLoggedIn && userHasProfile)
    return <Navigate to="/calculator" replace={true} />;

  return (
    <AuthLayout title="Profile details" isLoading={isLoading}>
      <div className="bg-blue-100 border border-blue-400 text-blue-600 text-center text-sm px-4 py-3 rounded mb-6">
        You are almost done! Please enter profile details to continue:
      </div>
      <form onSubmit={handleSubmit(handleProfileUpdate)}>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="name">
            First Name
          </label>
          <input
            type="text"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Enter first name"
            {...register("first_name")}
          />
        </fieldset>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="name">
            Last Name
          </label>
          <input
            type="text"
            className="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Enter last name"
            {...register("last_name")}
          />
        </fieldset>
        <fieldset className="mb-6">
          <input
            className="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
            disabled={!validateForm()}
            type="submit"
            value="Save and continue"
          />
          <div
            className={`bg-red-100 border border-red-400 text-red-600 text-center text-sm px-4 py-3 rounded mt-4 ${
              profileError ? "" : "hidden"
            }`}
          >
            {profileError}
          </div>
        </fieldset>
      </form>
      <div className="text-center">
        <span
          className="text-primary cursor-pointer"
          onClick={() => dispatch(logout())}
        >
          Logout
        </span>
      </div>
    </AuthLayout>
  );
};
