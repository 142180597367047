import React, { FC } from "react";

import { ColorCard } from "../../../pages/shared-components/color-card";

type SettingsCardType = {
  color: string;
  icon: React.ReactNode;
  text: string;
  title: string;
};

export const SettingsCard: FC<SettingsCardType> = ({
  color,
  icon,
  text,
  title,
}) => {
  return (
    <ColorCard color={color}>
      <div className="p-6">
        <div className="flex items-center mb-4">
          <div className={`bg-opacity-10 rounded p-3 mr-4 bg-${color}`}>
            <div className="w-6">{icon}</div>
          </div>
          <div className="font-semibold">{title}</div>
        </div>
        <div>{text}</div>
      </div>
    </ColorCard>
  );
};
