const defaultAllowedKeys = [
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'Delete',
  'Tab',
  '.'
]
export const numbersOnly = (event: any, customAllowedKeys: string[] = []) => {
  // Accept only numbers, no alphabets nor special characters(except .)
  const regex = /^\d+$/;
  const str = event.key;

  let allowedKeys = [...defaultAllowedKeys];

  if (customAllowedKeys.length > 0) {
    allowedKeys = [...allowedKeys, ...customAllowedKeys];
  }

  if (
    !regex.test(str) && !(allowedKeys.includes(str))
  ) {
    event.preventDefault();
  }
};
