import React, { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { AppLayout } from "../app-layout";
import { SettingsCard } from "./settings-card";
import { ReactComponent as BackIcon } from "../../../assets/icons/arrow-thick-dot-left.svg";

const styleTop3 = { top: "3rem" };

type SettingsLayoutType = {
  color: string;
  icon: React.ReactNode;
  text: string;
  title: string;
};
export const SettingsLayout: FC<PropsWithChildren<SettingsLayoutType>> = ({
  color,
  icon,
  text,
  title,
  children,
}) => {
  return (
    <AppLayout title="Settings">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/3 px-4 mb-8">
          <div className="sticky" style={styleTop3}>
            <div className="mb-4">
              <Link to="/settings" className="inline-flex items-center">
                <BackIcon className="w-4 mr-2" />
                <div className="text-gray-600 font-light">Back to settings</div>
              </Link>
            </div>
            <SettingsCard color={color} icon={icon} text={text} title={title} />
          </div>
        </div>
        <div className="w-full lg:w-2/3 px-4 lg:pt-10 mb-8">{children}</div>
      </div>
    </AppLayout>
  );
};
