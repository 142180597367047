import React, { useState, useEffect, useRef, Fragment } from "react";
import { FaAngleDown } from "react-icons/fa";
import capitalizeFirstLetter from "../../../../helpers/capitalizeFirstLetter";
import { ReactComponent as PlusIcon } from "../../../../icons/preferred_financier_addition.svg";
import { HorizontalDivider } from "../../form-components/divider";

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  onSelect: any;
  setvalue: any;
  name: any;
  cancelChanges: any;
  formData: any;
  clearData: any;
}

const CustomMultiselectWithChips: React.FC<Props> = ({
  options,
  onSelect,
  setvalue,
  name,
  cancelChanges,
  formData,
  clearData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const upperComponentRef = useRef<HTMLDivElement>(null);
  const [forceRefresh, setForceRefresh] = useState(0);

  useEffect(() => {
    setForceRefresh((prev) => prev + 1);
  }, [isOpen]);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleOptionSelect = (option: Option) => {
    const updatedSelectedOptions = [...selectedOptions, option];
    setSelectedOptions(updatedSelectedOptions);
    setvalue(name, updatedSelectedOptions);
    onSelect(updatedSelectedOptions); // Pass the updated selected options to onSelect
    setInputValue(""); // Clear the input value after selecting an option
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsOpen(true);
  };

  const handleChipRemove = (option: Option) => {
    const updatedSelectedOptions = selectedOptions.filter(
      (selectedOption) => selectedOption.value !== option.value
    );
    setvalue(name, updatedSelectedOptions);
    setSelectedOptions(updatedSelectedOptions);
    onSelect(updatedSelectedOptions); // Pass the updated selected options to onSelect
  };

  const filteredOptionsWithoutSelected = filteredOptions.filter(
    (option) =>
      !selectedOptions.find((selected) => selected.value === option.value)
  );

  useEffect(() => {
    if (formData) {
      setSelectedOptions(formData.preferred_financier || []);
    }
  }, [cancelChanges]);

  useEffect(() => {
    if (clearData) {
      setSelectedOptions([]);
    }
  }, [clearData]);

  const [isPlusIconHovered, setIsPlusIconHovered] = useState<
    number | undefined
  >(undefined);

  const handlePlusIconMouseEnter = (index: number) => {
    setIsPlusIconHovered(index);
  };

  const handlePlusIconMouseLeave = () => {
    setIsPlusIconHovered(undefined);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`relative ${
        selectedOptions.length !== 0 && isOpen && "mr-[-30%]"
      }`}
      ref={dropdownRef}
    >
      {isOpen && selectedOptions.length !== 0 && (
        <div
          className={`sticky top-0 bg-white z-20 px-1 py-2 items-end flex flex-wrap w-52 ml-[-30%] flex-row-reverse rounded-md`}
          ref={upperComponentRef}
          style={{ marginTop: `-${upperComponentRef.current?.clientHeight}px` }}
        >
          {selectedOptions.map((option) => (
            <div
              key={option.value}
              className="text-[#3e2d8d] bg-[#f2f2f2] p-1 w-fit rounded mx-2 my-1 hover:cursor-pointer hover:line-through hover:bg-[#cccccc]"
              onClick={() => handleChipRemove(option)}
            >
              {capitalizeFirstLetter(option.label)}
            </div>
          ))}
        </div>
      )}
      <div
        className={`flex flex-row items-center w-36 p-1 h-11 rounded border-4 ${
          isOpen
            ? "border-4 bg-white border-yellow text-black"
            : "bg-[#514298] text-white border-[#514298]"
        }`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onMouseEnter={() => handlePlusIconMouseEnter(-1)}
        onMouseLeave={() => handlePlusIconMouseLeave()}
      >
        {!isOpen && selectedOptions.length !== 0 ? (
          <div className="flex flex-row overflow-hidden h-6 w-40">
            <div className="font-[700]">
              {capitalizeFirstLetter(selectedOptions[0].label)}
              {selectedOptions.length > 1 && (
                <span>
                  {" (+"}
                  {selectedOptions.length - 1}
                  {" )"}
                </span>
              )}
            </div>
          </div>
        ) : (
          <input
            placeholder="Type"
            value={inputValue}
            autoFocus={isOpen}
            onChange={handleInputChange}
            className={`${
              !isOpen &&
              "bg-[#514298] placeholder:text-white placeholder:opacity-70"
            } w-28`}
          />
        )}
        <FaAngleDown />
      </div>
      {isOpen && filteredOptionsWithoutSelected.length !== 0 && (
        <ul
          className={`absolute z-10 w-52 ${
            selectedOptions.length !== 0 ? "ml-[-30%]" : "ml-[-47%]"
          } bg-white border mt-1 overflow-y-auto max-h-60 h-fit border-gray-300 rounded`}
        >
          {filteredOptionsWithoutSelected.map((option, index) => (
            <Fragment key={index}>
              <div
                className="flex cursor-pointer flex-row items-center hover:bg-black my-1 p-1 w-[94%] ml-[3%] rounded-md hover:text-[#fbca5d] justify-between"
                onMouseEnter={() => handlePlusIconMouseEnter(index)}
                onMouseLeave={() => handlePlusIconMouseLeave()}
                onClick={() => handleOptionSelect(option)}
              >
                <li className="px-3 py-3 font-[700] text-base">
                  {capitalizeFirstLetter(option.label)}
                </li>
                <PlusIcon
                  className={`mx-4 ${
                    isPlusIconHovered !== undefined &&
                    isPlusIconHovered === index
                      ? "block"
                      : "hidden"
                  }`}
                />
              </div>
              <HorizontalDivider marginX={3} />
            </Fragment>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomMultiselectWithChips;
