const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

// Authentication API URLs
export const REGISTER_API_URL = BASE_API_URL + "register/";
export const LOGIN_API_URL = BASE_API_URL + "login/";
export const USERS_API_URL = BASE_API_URL + "usersapi/";
export const EMAIL_VERIFY_API_URL = BASE_API_URL + "email-verify/?token=";

// Repayments API URLs
const BASE_REPAYMENTS_API_URL = BASE_API_URL + "repayments/";
export const CALCULATOR_API_URL = BASE_REPAYMENTS_API_URL + "calculator/";

// Products API URLs
export const PRODUCTS_API_URL = BASE_API_URL + "products/";
export const PRODUCTS_COMPANY_API_URL = PRODUCTS_API_URL + "?company_id=";

// Rate Brackets API URLs
export const RATE_BRACKETS_API_URL = BASE_API_URL + "rate_brackets/";
export const RATE_BRACKETS_PRODUCT_API_URL =
  RATE_BRACKETS_API_URL + "?product_id=";

// Uplifts API URLs
export const UPLIFTS_API_URL = BASE_API_URL + "uplifts/";

// Company API URLs
export const COMPANY_API_URL = BASE_API_URL + "company/";

export const FORGOT_PASSWORD_URL = BASE_API_URL + 'forgot-password/';
export const RESET_PASSWORD_URL = BASE_API_URL + 'reset-password';
