import SweetAlert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const sweetAlert = withReactContent(SweetAlert);

type NotifyConfirm = {
  confirmButtonText?: string;
  icon?: "warning" | "success" | "error" | "info" | "question" | undefined;
  isDestructive?: boolean;
  text: string;
  title: string;
};

export const Notify = {
  confirm: ({
    icon = "warning",
    text,
    title,
    confirmButtonText = "Yes",
    isDestructive = false,
  }: NotifyConfirm) => {
    return sweetAlert.fire({
      cancelButtonColor: "#a0aec0",
      cancelButtonText: "No",
      confirmButtonColor: isDestructive ? "#c53030" : "#5e549a",
      confirmButtonText,
      icon,
      reverseButtons: true,
      showCancelButton: true,
      showCloseButton: true,
      text,
      title,
    });
  },
};
