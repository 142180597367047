import { createSlice } from "@reduxjs/toolkit";

interface SortState {
  value: { label: string; value: string };
}

const initialState: SortState = {
  value: { label: "", value: "" },
};

const sortSlice = createSlice({
  name: "sort",
  initialState,
  reducers: {
    updateSort(state, action) {
      state.value = action.payload;
    },
  },
});

export const { updateSort } = sortSlice.actions;
export const sortState = (state: any) => state.sort.value;
export default sortSlice.reducer;
