import React, { FC, PropsWithChildren } from "react";

import { Illustration } from "./illustration";

type IllustrationCardType = {
  title: string;
  icon: string;
  color: string;
  isOffset?: boolean;
};

const styleBottomRight = { bottom: "-10px", right: "-10px" };

export const IllustrationCard: FC<PropsWithChildren<IllustrationCardType>> = ({
  title,
  icon,
  color,
  isOffset,
  children,
}) => {
  return (
    <div
      className={`w-full lg:w-1/3 px-6 text-center ${
        isOffset ? "pt-12 lg:pt-12" : "pt-12 lg:pt-0"
      }`}
    >
      <div className="relative flex flex-col">
        <div className="relative p-8 flex-auto bg-white border-2 border-brand rounded-lg z-10">
          <div className="w-20 h-20 mx-auto mb-4">
            <Illustration icon={icon} />
          </div>
          <h6 className="font-display text-2xl">{title}</h6>
          <p className="break-words mt-2">{children}</p>
        </div>
        <div
          className={`${color} absolute w-full h-full flex-auto border-2 border-brand rounded-lg`}
          style={styleBottomRight}
        ></div>
      </div>
    </div>
  );
};
