import React, { useEffect, useState, useRef } from "react";
import { HorizontalDivider } from "../../../form-components/divider";
import {
  getCompanies,
  getCompany,
} from "../../../../../services/company.service";
import {
  getProduct,
  getProductsOfCompany,
} from "../../../../../services/products.service";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDirectoryComapnyId,
  updateProductsData,
  updateDirectoryProductId,
  updateBracketData,
  updateUpliftData,
  clearUpliftData,
  updateLoading,
  updateorgLoading,
  updateproductLoading,
  updatebracketLoading,
  updateupliftLoading,
} from "../../../../../redux/slices/directorySlice";
import { directoryState as ds } from "../../../../../redux/slices/directorySlice";
import {
  getRateBracket,
  getRateBracketsOfProduct,
} from "../../../../../services/rate-brackets.service";
import {
  getUplift,
  getUpliftsOfProductAndRateBracket,
} from "../../../../../services/uplifts.service";
import { useMediaQuery } from "react-responsive";

interface DirectoryCustomDropdownProps {
  setCompanyData: React.Dispatch<React.SetStateAction<any>>;
  componentName: string;
  reloadData: any;
  hovered?: boolean;
  companyData?: any;
  setReloadData?: any;
  setRefetch?: any;
  refetch?: any;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const DirectoryCustomDropdown: React.FC<DirectoryCustomDropdownProps> = ({
  setCompanyData,
  componentName,
  reloadData,
  hovered,
  companyData,
}) => {
  const [selectedOption, setSelectedOption] = useState("Select");
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const directoryState = useSelector(ds);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    if (componentName === "CompanyFilter") {
      if(directoryState.selectedComapnyId===-1){
        setSelectedOption("Select");
      }
      getCompanies()
        .then((response) => {
          const mappedOptions = response.data.map((company: any) => ({
            value: company.id,
            label: company.name,
          }));
          setOptions(mappedOptions);
        })
        .catch((error) => {
          console.error("Error fetching companies:", error);
        });
    }
    if (componentName === "ProductsFilter" && directoryState) {
      if (!directoryState.productsData) {
        setSelectedOption("Select");
      }
      getProductsOfCompany(directoryState.selectedComapnyId)
        .then((response) => {
          const mappedOptions = response.data.map((product: any) => ({
            value: product.id,
            label: product.product_name,
          }));
          setOptions(mappedOptions);
        })
        .catch((exception) => console.log(exception));
    }
    if (componentName === "BracketFilter" && directoryState) {
      if (!directoryState.bracketData) {
        setSelectedOption("Select");
      }
      getRateBracketsOfProduct(directoryState.selectedProductId)
        .then((response) => {
          const mappedOptions = response.data.map((product: any) => ({
            value: product.id,
            label: product.bracket_name,
          }));
          setOptions(mappedOptions);
        })
        .catch((exception) => console.log(exception));
    }
    if (componentName === "UpliftFilter" && directoryState) {
      if (!directoryState.upliftdata) {
        setSelectedOption("Select");
      }
      getUpliftsOfProductAndRateBracket(
        directoryState.bracketData.product_id,
        directoryState.bracketData.id
      )
        .then((response) => {
          const mappedOptions = response.data.map((product: any) => ({
            value: product.id,
            label: product.uplift_name,
          }));
          setOptions(mappedOptions);
        })
        .catch((exception) => console.log(exception));
    }
  }, [
    componentName,
    directoryState.productsData,
    directoryState.bracketData,
    directoryState.selectedComapnyId,
    reloadData,
    companyData,
  ]);

  useEffect(() => {
    setSelectedOption("Select");
  }, [reloadData]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        listRef.current &&
        !listRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (name: string, id: number) => {
    if (componentName === "CompanyFilter") {
      dispatch(updateDirectoryComapnyId(id));
      dispatch(updateorgLoading(true));
      dispatch(updateBracketData(null));
      dispatch(updateUpliftData(null));
      dispatch(updateProductsData(null));
      getCompany(id).then((response) => {
        setCompanyData(response.data);
        dispatch(updateorgLoading(false));
      });
    }
    if (componentName === "ProductsFilter") {
      dispatch(updateDirectoryProductId(id));
      dispatch(updateBracketData(null));
      dispatch(updateUpliftData(null));
      dispatch(updateproductLoading(true));
      getProduct(id)
        .then((response) => {
          dispatch(updateProductsData(response.data));
          dispatch(updateproductLoading(false));
        })
        .catch((error) => console.log("Error getting product", error));
    }

    if (componentName === "BracketFilter") {
      dispatch(updateUpliftData(null));
      dispatch(clearUpliftData());
      dispatch(updatebracketLoading(true));
      getRateBracket(id)
        .then((response) => {
          dispatch(updateBracketData(response.data));
          dispatch(updatebracketLoading(false));
        })
        .catch((error) => console.log("Error getting rate bracket", error));
    }
    if (componentName === "UpliftFilter") {
      dispatch(updateupliftLoading(true));
      getUplift(id)
        .then((response) => {
          dispatch(updateUpliftData(response.data));
          dispatch(updateLoading(false));
          dispatch(updateupliftLoading(false));
        })
        .catch((error) => console.log("Error getting rate bracket", error));
    }
    setSelectedOption(name);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && listRef.current) {
      setDropdownHeight(listRef.current.getBoundingClientRect().height);
    }
  }, [isOpen, options]);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });

  const calculateDropdownPosition = () => {
    if (options.length === 0) return false;
    if (dropdownRef.current) {
      const dropdownRect = (
        dropdownRef.current as HTMLElement
      )?.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const bottomSpace = windowHeight - dropdownRect.bottom;
      return bottomSpace < 384;
    }
    return false;
  };

  return (
    <div className="relative pr-[2%]" ref={dropdownRef}>
      <div
        className={`rounded-md cursor-pointer appearance-none ${
          isSmallScreen ? "w-[38vw]" : "w-56"
        } py-1 px-1 ${
          !isOpen
            ? `border-4 border-transparent ${
                componentName === "CompanyFilter"
                  ? "bg-black text-white"
                  : hovered
                  ? "bg-[#6457a4] text-white"
                  : "bg-[#c9a24a] text-white"
              } `
            : "border-[#fbca5d] border-4 bg-white text-black"
        }`}
        onClick={toggleDropdown}
      >
        <div className="flex justify-between items-center">
          <span className={`${selectedOption !== "Select" && "font-bold"}`}>
            {capitalizeFirstLetter(selectedOption)}
          </span>
          <svg
            className={`w-4 h-4 transition-transform transform ${
              isOpen ? "-rotate-180" : "rotate-0"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <ul
          className={`absolute z-10 bg-white max-h-96 overflow-y-auto shadow rounded shadow-white mt-1 ${
            isSmallScreen ? "w-[38vw]" : "w-56"
          }`}
          style={{
            transform: calculateDropdownPosition()
              ? `translateY(-${dropdownHeight + 50}px)`
              : "none",
          }}
          ref={listRef}
        >
          {options.map((data: any, index) => {
            return (
              <React.Fragment key={data.value}>
                <li
                  className="cursor-pointer px-4 py-3 hover:bg-black hover:rounded hover:my-1 hover:py-2 hover:px-2 hover:mx-2 hover:text-[#dcb151] font-[700] break-words w-[calc(full-2.5rem)] text-black"
                  onClick={() => handleChange(data.label, data.value)}
                >
                  {capitalizeFirstLetter(data.label)}
                </li>
                {index + 1 !== options.length && (
                  <HorizontalDivider marginX={3} />
                )}
              </React.Fragment>
            );
          })}
          {options.length === 0 && (
            <li className="px-4 py-2 break-words w-full text-black">
              No data available
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default DirectoryCustomDropdown;
