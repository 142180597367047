import { PropsWithChildren, FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select, { components, OptionProps } from "react-select";
import { ReactComponent as Star } from "../../../icons/star.svg";
import { useMediaQuery } from "react-responsive";

type InputSelectType = {
  title: string;
  name: string;
  id: string;
  setValue: any;
  options: Array<{ value: string | number; label: string }>;
  showVertical: boolean;
  control: any;
  isMobile: boolean;
  defaultValue: any;
  reset: any;
  setReset: any;
  fixedWidth?: boolean;
  watch: any;
};

export const InputSelect: FC<PropsWithChildren<InputSelectType>> = ({
  title,
  name,
  id,
  options,
  showVertical,
  setValue,
  control,
  isMobile,
  defaultValue,
  reset,
  setReset,
  fixedWidth,
  watch,
}) => {
  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue]);
  const [inputfocused, setInputFocused] = useState(false);

  const CustomOption = (props: OptionProps) => (
    <div style={{ position: 'relative' }}>
      <components.Option {...props} />
      <div
        style={{
          height: '2px',
          background: '#e5e5e5',
          width: '80%',
          position: 'absolute',
          bottom: -3,
          marginLeft: "10%",
        }}
      />
    </div>
  );

  const isDesktopScreen = useMediaQuery({ query: "(max-width: 1840px)" });
  const isCustomDesktopScreen = useMediaQuery({ query: "(max-width: 1600px)" });
  const isCustomScreen = useMediaQuery({ query: "(max-width: 1482px)" });
  const isCustomScreenBig = useMediaQuery({ query: "(min-width: 1900px)" });

  return (
    <div
      className={`flex ${
        showVertical ? "flex-col mt-1" : "items-center flex-row"
      } px-4 py-2`}
    >
      <div
        // htmlFor={id}
        className={`flex ${
          !showVertical &&
          (title === "Repayment Frequency"
            ? !isDesktopScreen && isCustomDesktopScreen
              ? "w-[40%]"
              : isCustomScreen
              ? "w-[39%]"
              : "w-[34.5%]"
            : isCustomScreenBig
            ? "w-[40%]"
            : "w-[24%]")
        } items-center text-base text-[#b2abd1] ${
          showVertical
            ? "text-[#b2abd1] mt-[-1%] mb-[1%]"
            : "text-[#b2abd1] mr-[-3%]"
        }`}
      >
        {title} {title === "Term" && <Star className="mx-2" />}
      </div>
      <div
        className={`${showVertical && !isMobile ? " w-[9.8vw]" : "w-[9vw]"}  ${
          isMobile && "w-[100%]"
        }rounded-md max-w-[250px]`}
      >
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              options={options}
              value={
                (!reset || name !== "term") &&
                watch(name) &&
                options.find((c) => c.value === value)
              }
              onChange={(val: any) => {
                onChange(val.value);
                setReset(false);
              }}
              className={`${
                fixedWidth ? " w-full" : "w-full"
              } text-base tracking-wide rounded-md text-white bg-[#514298]`}
              placeholder="Select"
              onMenuOpen={() => setInputFocused(true)}
              onMenuClose={() => setInputFocused(false)}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderStyle: "none",
                  textAlign: "left",
                  background: `${inputfocused ? "white" : "#514298"}`,
                  boxShadow: "none",
                  color: "black",
                  height: "3rem",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                option: (baseStyles, { isFocused, data }) => ({
                  ...baseStyles,
                  textAlign: "left",
                  padding: 16,
                  width: "94%",
                  marginLeft: "3%",
                  borderRadius: 5,
                  fontSize: 14,
                  marginTop: 5,
                  background: isFocused
                    ? "black"
                    : // @ts-ignore
                    value === data.value
                    ? "#fbca5d"
                    : "white",
                  color: isFocused ? "#fbca5d" : "black",
                  cursor: isFocused ? "pointer" : "default",
                  fontWeight: isFocused ? "bold" : "normal",
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: `${inputfocused ? "black" : "white"}`,
                  fontWeight: "bold",
                  fontSize: "14px",
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "white",
                  fontWeight: "normal",
                  opacity: "70%",
                }),
              }}
              components={{
                Option: CustomOption,
              }}
              isSearchable={false}
              isClearable={false}
            />
          )}
        />
      </div>
    </div>
  );
};
