import { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import ResultListView from "./results-components/list-result/list-result-view";
import {
  optionType,
} from "./results-components/result-components.types";
import { RepaymentResultsContext } from "./calculator.page";
import EmptyFilters from "./EmptyFilters";
import { useSelector } from "react-redux";
import { sortState } from "../../../redux/slices/sortingFilterSlice";
import {directoryState as ds} from "../../../redux/slices/directorySlice";

export const Results: FC<PropsWithChildren> = () => {
  const { repaymentResultsData } = useContext<any>(RepaymentResultsContext);

  const SortState = useSelector(sortState);

  const directorySlice = useSelector(ds);

  useEffect(()=>{
    handleSortByChange(SortState);
  }, [SortState])

  const [data, setData] = useState<[] | {}>({});

  const [isGridView, setIsGridView] = useState(true);
  const [gridSortBy, setGridSortBy] = useState<optionType | null>(null);
  const [listSortBy, setListSortBy] = useState<optionType | null>(null);
  const [groupBy, setGroupBy] = useState<optionType | null>(null);

  function handleViewChange() {
    setIsGridView(!isGridView);
  }

  function getDataGroupedBy(
    groupBy: optionType | null,
    sortBy: optionType | null
  ) {
    setGroupBy(groupBy);
    if (isGridView) {
      setGridSortBy(sortBy);
      setListSortBy(null);
    } else {
      setListSortBy(sortBy);
      setGridSortBy(null);
    }
    let groupedData: any = {};
    let sortedGroupedData: any = [];

    if (!groupBy || groupBy["value"] === "financier") {
      repaymentResultsData.forEach((item: any) => {
        const productName = item.product_name;
        if (!groupedData[productName]) {
          groupedData[productName] = [];
        }
        groupedData[productName].push(item);
      });
    } else {
      repaymentResultsData.forEach((item: any) => {
        const rateBracketName = item.rate_bracket_name;
        if (!groupedData[rateBracketName]) {
          groupedData[rateBracketName] = [];
        }
        groupedData[rateBracketName].push(item);
      });
    }

    // sort by financier
    if (sortBy) {
      if (sortBy.value === "financier") {
        groupedData = Object.entries(groupedData);
        // Sort the array by product name within each group
        groupedData.forEach((group: any) => {
          group[1].sort((a: any, b: any) =>
            a.product_name.localeCompare(b.product_name)
          );
        });

        // Sort the groups by their product names
        sortedGroupedData = [...groupedData].sort((a: any, b: any) => {
          const productNameA = a[1][0].product_name.toUpperCase();
          const productNameB = b[1][0].product_name.toUpperCase();
          if (productNameA < productNameB) {
            return -1;
          }
          if (productNameA > productNameB) {
            return 1;
          }
          return 0;
        });

        // Change format from array to Object
        sortedGroupedData = sortedGroupedData.reduce(
          (obj: any, [key, data]: any) => {
            obj[key] = data;
            return obj;
          },
          {}
        );
      } else {
        // sort by repayment, (min)base rate and (min)effective
        groupedData = Object.entries(groupedData);
        // Sort the array by product name within each group
        groupedData.forEach((group: any) => {
          group[1].sort((a: any, b: any) => compareRate(a, b, sortBy.value));
        });

        // Sort the groups by their product names
        sortedGroupedData = groupedData.sort((a: any, b: any) => {
          const a_min = Math.min(
            ...a[1].map((item: any) => {
              return item[
                (sortBy.value === "effective" ? "r" : sortBy.value) + "_min"
              ];
            })
          );
          const b_min = Math.min(
            ...b[1].map((item: any) => {
              return item[
                (sortBy.value === "effective" ? "r" : sortBy.value) + "_min"
              ];
            })
          );
          return compareRate(a_min, b_min, sortBy.value);
        });

        // Change format from array to Object
        sortedGroupedData = sortedGroupedData.reduce(
          (obj: any, [key, data]: any) => {
            const minValue = Math.min(
              ...data.map(
                (item: any) =>
                  item[
                    (sortBy.value === "effective" ? "r" : sortBy.value) + "_min"
                  ]
              )
            );

            const newKey = `${minValue}-${
              !groupBy || groupBy["value"] === "financier"
                ? data[0].product_name
                : data[0].rate_bracket_name
            }`;

            obj[newKey] = data;
            return obj;
          },
          {}
        );
        sortedGroupedData = Object.fromEntries(
          Object.entries(sortedGroupedData).sort(([keyA], [keyB]) => {
            const valueA = parseFloat(keyA.split("-")[0]);
            const valueB = parseFloat(keyB.split("-")[0]);
            return valueA - valueB;
          })
        );
      }
    }
    setData(sortedGroupedData);
  }

  function compareRate(rate1: any, rate2: any, param: string | undefined) {
    switch (param) {
      case "repayment":
        return rate1.repayment_min - rate2.repayment_min;
      case "effective":
        return rate1.r_min - rate2.r_min;
      case "base":
        return rate1.base_min - rate2.base_min;
      default:
        return 0;
    }
  }

  function handleSortByChange(option: optionType | null) {
    if (isGridView) {
      setGridSortBy(option);
      setListSortBy(null);
    } else {
      setListSortBy(option);
      setGridSortBy(null);
    }
  }

  useEffect(() => {
    if (repaymentResultsData && repaymentResultsData.length > 0) {
      if (isGridView) {
        !gridSortBy
          ? getDataGroupedBy(null, { label: "Financier", value: "financier" })
          : getDataGroupedBy(null, gridSortBy);
      } else {
        !groupBy
          ? !listSortBy
            ? getDataGroupedBy(
                { label: "Financier", value: "financier" },
                { label: "Financier", value: "financier" }
              )
            : getDataGroupedBy(
                { label: "Financier", value: "financier" },
                listSortBy
              )
          : !listSortBy
          ? getDataGroupedBy(groupBy, {
              label: "Financier",
              value: "financier",
            })
          : getDataGroupedBy(groupBy, listSortBy);
      }
    }
  }, [isGridView, groupBy, gridSortBy, listSortBy, repaymentResultsData]);

  return (
    <div className={`${directorySlice.isLoading && "opacity-40"}`}>
      <div className="">
        {(repaymentResultsData && repaymentResultsData.length) > 0 ? (
          // isGridView ? (
          //   <ResultGridView repaymentResultsData={data} onViewChange={handleViewChange}/>
          // ) : (
          //   <ResultListView repaymentResultsData={data} onViewChange={handleViewChange}/>
          // )
          <ResultListView repaymentResultsData={data} onViewChange={handleViewChange}/>
        ) : (
          <EmptyFilters />
        )}
      </div>
    </div>
  );
};
